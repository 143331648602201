import React from "react";
import Slider from "react-slick";
import {Card } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



class SimpleSlider extends React.Component {
    render() {
        var settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow:2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                arrows:false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows:false
              }
            }
          ]
        };
      return (
        <Slider {...settings}>

          <div className="slider-item">
            <Card>
                <Card.Body>
                    <div className="user_section">
                        <div className="useavatar">
                            <img src={require('assets/images/01.png')} alt="user1"/> 
                        </div>
                        <div className="usedes">
                            <p>Chris Leander</p>
                            <p><span>@SimpleDentist</span></p>
                        </div>
                    </div>

                    <div className="card-content">
                        <p>I continued to be impressed by <span>@LocalCall leads </span> 
                        Their team works very hard to growth my practice every week <span>#LocalBizDentist</span>Let’s face it: Competition on Local Biz it is near cutthroat levels. 
                        You need a partner that will give you an advantage. </p>
                    </div>
                    
                </Card.Body>
            </Card>
          </div>


          <div className="slider-item">
            <Card>
                <Card.Body>
                    <div className="user_section">
                        <div className="useavatar">
                            <img src={require('assets/images/02.png')} alt="user1"/> 
                        </div>
                        <div className="usedes">
                            <p>Tony Melendy</p>
                            <p><span>@HeyItsTony</span></p>
                        </div>
                    </div>

                    <div className="card-content">
                        <p>Wowww 53 leads last month! <span>#LocalCallRocks!</span>&nbsp;
                 I've signed with LocalCall Lead generation to increase my clients and they did not disappoint me. They know a ton about my industry, they are willing to go the extra mile. 
                        Definitely teaming up with them foreverrrr! Tony Melendy
                        </p>
                    </div>
                    
                </Card.Body>
            </Card>
          </div>

          <div className="slider-item">
            <Card>
                <Card.Body>
                    <div className="user_section">
                        <div className="useavatar">
                            <img src={require('assets/images/03.png')} alt="user1"/> 
                        </div>
                        <div className="usedes">
                            <p>Angela Simo Brown</p>
                            <p><span>@AngelaYogaStudio</span></p>
                        </div>
                    </div>

                    <div className="card-content">
                        <p>I reached out to LocalCall to expand my Yoga business through B2B lead
generation. They continued to show true willingness to make sure I am going to
succeed. I love their program for lead generation, stay tuned! :) Thanks
Angela! <span>#AngelaYogaStudio</span></p>
                    </div>
                    
                </Card.Body>
            </Card>
          </div>

          <div className="slider-item">
            <Card>
                <Card.Body>
                    <div className="user_section">
                        <div className="useavatar">
                            <img src={require('assets/images/04.png')} alt="user1"/> 
                        </div>
                        <div className="usedes">
                            <p>Jessica Noboa</p>
                            <p><span>@JessNoboa</span></p>
                        </div>
                    </div>

                    <div className="card-content">
                        <p>I'm the owner of couple Gyms. I couldn't be more pleased with the results this far. In under 5 weeks we have received over 185 leads. This is a get leads in a box type setup. 
                            Your results will mostly depend on your effort to close these leads. <span>#MoreLeads</span></p>
                    </div>
                    
                </Card.Body>
            </Card>
          </div>

          <div className="slider-item">
            <Card>
                <Card.Body>
                    <div className="user_section">
                        <div className="useavatar">
                            <img src={require('assets/images/05.png')} alt="user1"/> 
                        </div>
                        <div className="usedes">
                            <p>Brooke McDaniel</p>
                            <p><span>@BrookeAMcDaniel</span></p>
                        </div>
                    </div>

                    <div className="card-content">
                        <p>So if you're a hyper skeptic like me, you don't believe anyone or anything even if they show you so called "proof' then you'll appreciate my testimony. 
                            I sent LocalCall a personal message and asked them if they were ABSOLUTELY CERTAIN that could help me generate leads in california. 
                            I talked to them and seemed like There weren't giving me any BS. Now I'm one of their loyal customers <span>#LocalCalResults</span></p>
                    </div>
                    
                </Card.Body>
            </Card>
          </div>




         
        </Slider>
      );
    }
  }
  export default SimpleSlider;