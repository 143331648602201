import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { leadsActions } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { LeadCard } from "components";

import "./Leads.css";

export const Leads = () => {
  const { content, loading, error } = useSelector(({ leads }) => leads);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(leadsActions.getLeadsRequest());
  }, [dispatch]);

  if (loading) return <div>loading...</div>;
  else if (error) return <div>Error. Please refresh!</div>;

  return (
    <React.Fragment>
      <Container>
        <Row className="justify-content-center">
          <Col md="10">
            <div className="project_hero">
              <h1>These customers contracted others pros first.</h1>
              <p className="mt-2">
                You may be able to get the if those pros fallthrough. To get
                more leads, you need to stand out.
              </p>
              <p>
                <span className="highlight">Look yourself up</span>
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <h3 className="title mb-3">Leads</h3>
          </Col>
          <Col xs="6">
            <select className="tp-select tp-select--small">
              <option>Within 150 miles</option>
            </select>
          </Col>
          <Col xs="6">
            <select className="tp-select tp-select--small float-right">
              <option>Most relevant</option>
            </select>
          </Col>
        </Row>

        {
          <Row>
            {content &&
              content.data.map(lead => (
                <LeadCard key={lead.lead_id} {...lead} />
              ))}
          </Row>
        }
      </Container>
    </React.Fragment>
  );
};
