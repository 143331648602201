import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  ForgotPassword,
  ConfirmCode,
  Settings,
  Dashboard,
  Photos,
  Profile,
  Reviews,
  Job,
  Leads,
  Login,
  Nomatch,
  Home,
  Signup,
  Join,
  ChangePassword,
  GetStarted,
  Tos,
  Privacy
} from "pages";
import { PrivateRoute } from "components";
import { connect } from "react-redux";

const mapStateToProps = ({ authentication }) => ({
  isLoggedIn: authentication.loggedIn
});

const Routes = ({ isLoggedIn = false }) => {
  const LoggedInRoute = props => (
    <PrivateRoute isPermitted={isLoggedIn} {...props} />
  );

  const UnauthenticatedRoute = props => (
    <PrivateRoute
      isPermitted={!isLoggedIn}
      redirectTo="/dashboard"
      {...props}
    />
  );
  return (
    <Switch>
      <Route path="/join" component={Join} />

      <UnauthenticatedRoute exact path="/" component={Home} />
      <UnauthenticatedRoute exact path="/get-started" component={GetStarted} />
      <UnauthenticatedRoute path="/login" component={Login} />
      <UnauthenticatedRoute path="/signup" component={Signup} />
      <UnauthenticatedRoute path="/confirmation" component={ConfirmCode} />
      <UnauthenticatedRoute path="/profile" component={Profile} />
      <UnauthenticatedRoute
        path="/forgot-password"
        component={ForgotPassword}
      />
      <UnauthenticatedRoute
        path="/change-password"
        component={ChangePassword}
      />
      <UnauthenticatedRoute path="/tos" component={Tos} />
      <UnauthenticatedRoute path="/privacy" component={Privacy} />

      <LoggedInRoute path="/leads" component={Leads} />
      <LoggedInRoute path="/job" component={Job} />
      <LoggedInRoute path="/photos" component={Photos} />
      
      <LoggedInRoute path="/reviews" component={Reviews} />
      <LoggedInRoute path="/dashboard" component={Dashboard} />
      <LoggedInRoute path="/settings" component={Settings} />
      <Route component={Nomatch} />
    </Switch>
  );
};

export default connect(mapStateToProps)(Routes);
