import React, { useEffect } from "react";
import { useAlert } from "helpers";
import { Row, Col, Container, Card } from "react-bootstrap";
import { userService } from "services";
import { Link } from "react-router-dom";

import "./Dashboard.css";

export const Dashboard = () => {
  const [, , warningAlert] = useAlert();

  useEffect(() => {
    userService.getUserEntity().catch(() =>
      warningAlert(
        <span>
          You need to complete your profile by clicking{" "}
          <Link to="/profile">here</Link>
        </span>
      )
    );
  }, [warningAlert]);

  return (
    <React.Fragment>
      <section className="section-default min-vh-100 bg-white">
        <Container>
          <Row>
            <Col md="2"></Col>
            <Col md="8">
              <div className="dashboard_hero">
                <h1>Dashboard</h1>
                <ul className="dashboard_menu">
                  <li className="active">
                    <a href="#/">Your Activity</a>
                  </li>
                  <li>
                    <a href="#/">Your Top Pro status</a>
                  </li>
                  <li>
                    <a href="#/">How you compare</a>
                  </li>
                </ul>
              </div>

              <div className="over_view">
                <h4>4 Week Overview</h4>
                <p>August 19- Sept 15</p>
              </div>

              <Row>
                <Col md="4">
                  <Card className="dah_card active">
                    <h4>View</h4>
                    <big>0</big>
                    <p>
                      <span>
                        <i className="la la-arrow-up"></i> 0
                      </span>{" "}
                      vs. a week ago
                    </p>
                  </Card>
                </Col>

                <Col md="4">
                  <Card className="dah_card">
                    <h4>Leads</h4>
                    <big>0</big>
                    <p>
                      <span>
                        <i className="la la-arrow-up"></i> 0
                      </span>{" "}
                      vs. a week ago
                    </p>
                  </Card>
                </Col>

                <Col md="4">
                  <Card className="dah_card">
                    <h4>Spent</h4>
                    <big>$0</big>
                    <p>
                      <span>
                        <i className="la la-arrow-up"></i> $0
                      </span>{" "}
                      vs. a week ago
                    </p>
                  </Card>
                </Col>
              </Row>

              <div className="activity">
                <p>
                  <img src={require("assets/images/dash.jpg")} alt="" />
                </p>
                <h2>No Activity</h2>
                <p>
                  <span>Build a solid profile</span> so customer can find you
                </p>
              </div>

              <div className="table_area">
                <table className="tp-table tp-table--rule-bottom">
                  <thead>
                    <tr>
                      <th>By Service</th>
                      <th>View</th>
                      <th>Leads</th>
                      <th>Avg. Per lead</th>
                      <th>Spent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Reliki lesson</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Meditation instruction</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-3">
                  You got <strong>0</strong> additional views.
                </p>
              </div>
            </Col>
            <Col md="2"></Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
