import { userConstants } from "../constants";

export const loginRequest = payload => ({
  type: userConstants.LOGIN_REQUEST,
  payload
});

export const loginSuccess = payload => ({
  type: userConstants.LOGIN_SUCCESS,
  payload
});

export const loginError = error => ({
  type: userConstants.LOGIN_FAILURE,
  error
});

export const logoutRequest = () => ({
  type: userConstants.LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: userConstants.LOGOUT_SUCCESS
});

export const logoutError = error => ({
  type: userConstants.LOGOUT_FAILURE,
  error
});
export const confirmRequest = () => ({
  type: userConstants.CONFIRM_REQUEST
});

export const confirmSuccess = () => ({
  type: userConstants.CONFIRM_SUCCESS
});

export const confirmError = error => ({
  type: userConstants.CONFIRM_FAILURE,
  error
});

export const userActions = {
  loginSuccess,
  loginError,
  loginRequest,
  logoutSuccess,
  logoutError,
  logoutRequest,
  confirmSuccess,
  confirmRequest,
  confirmError
};
