import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import rootSaga from "sagas";
import localforage from "localforage";
import { persistStore, persistReducer } from "redux-persist";
import { utils } from "helpers";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "reducers";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();

const persistConfig = {
  key: "root",
  storage: localforage,
  blacklist: ["alert", "router"]
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const middlewares = utils.isDev
  ? [sagaMiddleware, routerMiddleware(history), loggerMiddleware]
  : [sagaMiddleware, routerMiddleware(history)];

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
