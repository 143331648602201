import React from "react";
import "./Reviews.css";
import { Row, Col, Container, Card } from "react-bootstrap";
export const Reviews = () => (
  <React.Fragment>
    <section className="section-default min-vh-100">
      <Container>
        <Row>
          <Col md="4"></Col>
          <Col md="4">
            <div className="reviews_heading">
              <h1>Get reviews from past customers</h1>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
          </Col>
          <Col md="3"></Col>
        </Row>

        <Row>
          <Col md="4"></Col>
          <Col md="4">
            <div className="reviews_form">
              <h4 className="mb-2">Get reviews from past customers</h4>
              <input
                type="email"
                className="tp-text-input tp-text-input--small"
                placeholder="Email 1"
              />
              <a href="#/" className="addcus">
                <i className="la la-plus-circle"></i> Add customer
              </a>
            </div>
          </Col>
          <Col md="4">
            <Card className="border-left-sse">
              <h4>Reviews make your competive</h4>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="4"></Col>
          <Col md="4">
            <div className="review_card_holder">
              <p className="mb-2">Email preview</p>
              <Card className="reviewcard align-items-center">
                <div className="imge_thumb">
                  <img src={require("assets/images/map.jpg")} alt="" />
                </div>
                <h4>
                  <strong>Medications</strong>
                </h4>
                <p className="star_ratings">
                  <i className="la la-star"></i>
                  <i className="la la-star"></i>
                  <i className="la la-star"></i>
                  <i className="la la-star"></i>
                  <i className="la la-star-half"></i>
                </p>

                <p className="mt-2">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
                <p className="mt-2">Thanks, Medications</p>
              </Card>

              <p>
                <a href="#/" className="addcus mt-5">
                  Get shareable links
                </a>
              </p>
            </div>
          </Col>
          <Col md="3"></Col>
        </Row>
      </Container>
    </section>

    <section className="steptrigger">
      <Container>
        <Row>
          <Col md="12">
            <div className="tp-button-row tp-button-row--right">
              <button className="tp-button tp-button--tertiary tp-button--small">
                Skip
              </button>
              <button className="tp-button tp-button--small">
                Send Request
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>
);
