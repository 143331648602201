import React from "react";

export const TextInput = React.forwardRef(
  ({ hasError = false, className = "", ...props }, ref) => {
    return (
      <input
        className={`tp-text-input tp-text-input--small ${hasError &&
          "tp-text-input--bad-news"} ${className}`}
        {...props}
        ref={ref}
      />
    );
  }
);
