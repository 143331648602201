import React, { useState, useEffect } from "react";
import { regex, useAlert } from "helpers";
import { userService } from "services";
import { Modal, Row, Col } from "react-bootstrap";
import { FormNote, Label } from "@thumbtack/thumbprint-react";
import useForm from "react-hook-form";
import PropTypes from "prop-types";
import { TextInput, InputMasked } from "components";
import { masks } from "helpers";

import "./GetStartedModal.css";

const GetStartedModal = ({ toggled, toggle }) => {
  const [setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    errors,
    handleSubmit,
    triggerValidation,
    setValue
  } = useForm();
  const [successAlert] = useAlert();

  useEffect(() => {
    register(
      { name: "phone" },
      {
        required: "This field is required.",
        minLength: {
          value: 10,
          message: "Phone must have 10 digits"
        }
      }
    );
  }, [register]);

  const onSubmit = async formData => {
    try {
      setLoading(true);
      const { data } = await userService.getStarted(formData);
      if (data && data.CodeDeliveryDetails) {
        successAlert("Please check your email for further instructions!");

        toggle();
      }
    } catch (err) {
      setError(
        err.status === 500
          ? "Oops. Something went wrong!"
          : err.response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneChange = async ({ target: { value } }) => {
    setValue("phone", value.replace(/[\s()-]/g, ""));
    await triggerValidation({ name: "phone" });
  };

  return (
    <Modal show={toggled} onHide={toggle} centered>
      {error && <div className={`alert modal-danger fade show`}>{error}</div>}
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="my_modal_content">
          <Row>
            <Col md="12">
              <h4>Get Started</h4>
              <p className="mt-3 mb-3">
                Where would you like meditation instruction customers to contact
                you?
              </p>
            </Col>

            <Col md="6">
              <Label for="email" hasError={errors.hasOwnProperty("email")}>
                Email Address
              </Label>
              <TextInput
                type="email"
                id="email"
                hasError={errors.email}
                placeholder="abcd@email.com"
                ref={register({
                  required: "This field is required.",
                  pattern: {
                    value: regex.email,
                    message: "Please enter an email address."
                  }
                })}
                name="email"
              />
              {errors.email && (
                <div className="mb1">
                  <FormNote hasError>{errors.email.message}</FormNote>
                </div>
              )}
            </Col>

            <Col md="6">
              <Label
                for="password"
                hasError={errors.hasOwnProperty("password")}
              >
                Password
              </Label>
              <TextInput
                id="password"
                type="password"
                hasError={errors.password}
                placeholder="Enter your password..."
                ref={register({
                  required: "This field is required.",
                  pattern: {
                    value: regex.password,
                    message:
                      "password must contain at least 1 uppercase letter and 1 special character."
                  },
                  min: {
                    value: 8,
                    message: "Password must have a minimum of 8 characters"
                  }
                })}
                name="password"
              />
              {errors.password && (
                <div className="mb1">
                  <FormNote hasError>{errors.password.message}</FormNote>
                </div>
              )}
            </Col>

            <Col md="12 mt-3">
              <Label for="phone" hasError={errors.hasOwnProperty("phone")}>
                Phone Number
              </Label>
              <InputMasked
                guide={false}
                mask={masks.phone}
                type="tel"
                id="phone"
                onChange={handlePhoneChange}
                hasError={errors.phone}
                placeholder="(713) 834-2650"
                name="phone"
              />
              {errors.phone && (
                <div className="mb1">
                  <FormNote hasError>{errors.phone.message}</FormNote>
                </div>
              )}
            </Col>

            <Col md="12" className="mt-4 mb-3">
              <div className="tp-input-wrap">
                <input
                  className="tp-checkbox-input"
                  type="checkbox"
                  id="enableMessage"
                  ref={register}
                  name="enableMessage"
                />
                <div className="tp-checkbox-image"></div>
                <label className="tp-label" htmlFor="enableMessage">
                  <strong>Enable text messages (optional)</strong>
                  <p>
                    By Checking the box, you electronically authorize Thunbtrack
                    to send you automated text messages to notify you of
                    customer activity and about our Service,
                  </p>
                </label>
              </div>
            </Col>
            <Col md="12">
              <label className="mt-3 mb-3">
                How much do you spend each week on online marketing?(optional)
              </label>
              <select
                ref={register}
                name="marketingTime"
                className="tp-select tp-select--full tp-select--small "
              >
                <option>Choose option</option>
                <option>$1 - $25</option>
              </select>
            </Col>
            <Col md="12">
              <p className="mt-3 mb-3">
                By Clicking Continue, you agree to the{" "}
                <a href="#/" className="link-default">
                  Terms of use
                </a>{" "}
                and
                <a href="#/" className="link-default">
                  Privacy policy
                </a>
              </p>
            </Col>

            <Col md="12">
              <button className="tp-button tp-button--full"  width="full">
                Continue
              </button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

GetStartedModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired
};

export { GetStartedModal };
