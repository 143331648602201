import { leadsConstants } from "../constants";
import produce from "immer";

export const leads = produce((draft = {}, action) => {
  switch (action.type) {
    case leadsConstants.GET_LEADS_REQUEST:
      draft.loading = true;
      break;
    case leadsConstants.GET_LEADS_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.content = action.leads;
      break;
    case leadsConstants.GET_LEADS_FAILURE:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
      return draft;
  }
});
