import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import places from "places.js";
import { TextInput } from "components";

import "./AddressAutocomplete.css";

const AddressAutocomplete = React.forwardRef(
  ({ onChange, className = "", ...props }, ref) => {
    const placesAutocomplete = useRef(null);
    useEffect(() => {
      if (onChange) {
        placesAutocomplete.current = places({
          appId: process.env.REACT_PLACES_APP_ID,
          apiKey: process.env.REACT_PLACES_API_KEY,
          container: document.querySelector(
            ".tp-text-input--places-autocomplete"
          ),
          templates: {
            value: ({ name }) => name
          }
        });
        placesAutocomplete.current.on("change", onChange);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <TextInput
        ref={ref}
        onBlur={() => placesAutocomplete.current.close()}
        type="search"
        className={`tp-text-input--places-autocomplete ${className}`}
        {...props}
      />
    );
  }
);

AddressAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default AddressAutocomplete;
