import { useState } from "react";
import { store } from ".";
import { alertActions } from "actions";

export function useToggle() {
  const [toggled, changeState] = useState(false);

  function toggle() {
    changeState(!toggled);
  }

  return [toggled, toggle];
}

export function useAlert() {
  const { dispatch } = store;
  function success(message) {
    dispatch(alertActions.success(message));
  }
  function warning(message) {
    dispatch(alertActions.warning(message));
  }
  function error(message) {
    dispatch(alertActions.error(message));
  }
  function clear() {
    dispatch(alertActions.clear());
  }

  return [success, error, warning, clear];
}
