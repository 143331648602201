import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { TextInput } from "components";

const InputMasked = React.forwardRef(({ mask, ...props }, ref) => (
  <MaskedInput
    {...props}
    mask={mask}
    ref={ref}
    render={(maskedRef, props) => {
      return <TextInput ref={maskedRef} {...props} />;
    }}
  />
));

InputMasked.propTypes = {
  mask: PropTypes.array.isRequired
};

export default InputMasked;
