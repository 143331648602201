import React, { useEffect } from "react";
import { Layout, Navigation } from "components";
import { Container, Row, Col } from "react-bootstrap";
import Routes from "./Routes";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlert } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "actions";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@thumbtack/thumbprint-scss";
import "./assets/line-awesome/css/line-awesome.min.css";
import "./assets/css/style.css";


const mapStateToProps = state => ({
  alert: state.alert
});

const App = ({ alert }) => {
  const { listen } = useHistory();
  const [, , , clearAlert] = useAlert();
  const dispatch = useDispatch();
  const user = useSelector(({ authentication }) => authentication.user);

  useEffect(() => {
    listen(() => alert.message && clearAlert());
  }, [listen, alert.message, clearAlert]);

  useEffect(() => {
    if (user) {
      const current_time = Date.now() / 1000;
      const timeout = (user.exp - current_time) * 1000; //Time remaining before token expires
      if (timeout <= 0) dispatch(userActions.logoutRequest());

      const timeoutID = setTimeout(
        () => dispatch(userActions.logoutRequest()),
        timeout
      );

      return () => clearTimeout(timeoutID);
    }
  }, [user, dispatch]);

  return (
    <React.Fragment>
      <Navigation />
      {alert.message && (
        <div className={`alert ${alert.type} fade show`}>{alert.message}</div>
      )}
      <Layout>
        <Routes />
      </Layout>

      <footer className="footer">
        <Container>
          <Row>
            <Col md="4"><div class="powered">Made with <i class="la la-heart"></i> in Austin TX.</div><div class="footer-copy">© Copyright All Right Reserved 2020 </div></Col>
            <Col md="8">
              <ul className="footer-menu">
                <li>
                  <a href="mailto:support@localcall.io">Help</a>
                </li>
                <li>
                  <a href="/tos">Terms of use</a>
                </li>
                <li>
                  <a href="/privacy">Privacy</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

const connectedApp = connect(
  mapStateToProps,
  null
)(App);

export { connectedApp as App };
