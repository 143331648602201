import React, { useState, createContext, useContext, useEffect } from "react";
import {
  Button,
  Label,
  ModalDefaultHeader,
  ButtonRow,
  ModalDefault,
  ModalDefaultTitle,
  ModalDefaultContent,
  FormNote
} from "@thumbtack/thumbprint-react";
import { Row, Col, Container, Card, Badge } from "react-bootstrap";
import { TextInput, AddressAutocomplete, InputMasked } from "components";
import { regex, useAlert, masks } from "helpers";
import useForm from "react-hook-form";
import { userService } from "services";
import { useHistory } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import {
  Elements,
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";
import Slider from "react-rangeslider";

import "./ProfileWizard.css";
import "react-rangeslider/lib/index.css";

const stepsContext = createContext();
const initialForm = {
  addresses: [],
  business_name: "",
  date_of_birth: "",
  first_name: "",
  middle_names: "",
  last_name: "",
  gender: "",
  //Hard coded values
  additional: {
    custom_id: "xxx81828128",
    etc: "Etc",
    special_rate: 30,
    vip: true
  },
  entity_type:"BUSINESS",
  business_hours: "none",
  total_leads: 0,
  remaining_leads_subscription: 0,
  last_ssn_itin: 123,
  verified: true,
  preferred_language: "eng",
  pos_product: false,
  no_products: false,
  marketing_product: false,
  appointment_product: false,
  invoice_product: false,
  phone_numbers: []
};

export const Wizard = () => {
  const [currentStep, setStep] = useState(1);
  const [formData, setFormData] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const [successAlert, errorAlert] = useAlert();

  useBeforeunload(event => event.preventDefault());

  const nextStep = data => {
    setFormData(form => ({ ...form, ...data }));

    if (currentStep === 3) {
      return submitProfile({ ...formData, ...data });
    }

    setStep(currentStep => (currentStep >= 3 ? 3 : currentStep + 1));
  };

  const submitProfile = async reqData => {
    try {
      setLoading(true);
      const { data } = await userService.createProfile(reqData);

      if (data) {
        successAlert("Successfully updated profile");
        push("/dashboard");
      }
    } catch (err) {
      errorAlert(
        err.response.status === 500
          ? "Oops. Something went wrong!"
          : err.response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValues = {
    currentStep,
    nextStep,
    formData,
    submitProfile,
    loading
  };

  return (
    <section className="profile section-default step-form ">
      <Container>
        <stepsContext.Provider value={contextValues}>
          <Step1 />
          <Step2 />
          <Elements>
            <Step3 />
          </Elements>
        </stepsContext.Provider>
      </Container>
    </section>
  );
};

const StepTrigger = ({ step }) => {
  const { loading } = useContext(stepsContext);

  return (
    <section className="steptrigger">
      <Container>
        <Row>
          <Col md="12" className="text-right">
            {step === 3 ? (
              <button type="submit" isLoading={loading}>
                Let's Go
              </button>


            ) : (
              <Button type="submit">Next</Button>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

function Step1() {
  const { currentStep, nextStep, formData } = useContext(stepsContext);
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: formData
  });

  useEffect(() => {
    register(
      { name: "phone" },
      {
        required: "This field is required.",
        minLength: {
          value: 10,
          message: "Phone must have 10 digits"
        }
      }
    );
  }, [register]);

  if (currentStep !== 1) {
    return null;
  }

  const onSubmit = ({
    day,
    month,
    year,
    phone,
    extension,
    middle_names,
    ...rest
  }) => {
    const date_of_birth = new Date(`${month}/${day}/${year}`);

    const data = {
      middle_names: middle_names ? middle_names : " ",
      date_of_birth,
      phone_numbers: [
        {
          extension: extension ? extension : " ",
          name: "Mobile",
          sms: false,
          verified: false,
          number: phone
        }
      ],
      ...rest
    };
    nextStep(data);
  };

  const handlePhoneChange = async ({ target: { value } }) => {
    setValue("phone", value.replace(/[\s()-]/g, ""));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Row>
        <Col md="3"></Col>
        <Col md="6 box-holder">
          <div className="short-des">
            <h3>Your Personal Info.</h3>
            <p>Customers will see this on your profile</p>
          </div>

          <ol className="tp-form-fields">
            <li className="tp-form-field__item">
              <Row>
                <Col lg="4">
                  <Label
                    hasError={errors.hasOwnProperty("first_name")}
                    for="first-name"
                    className="tp-label"
                  >
                    First Name
                    <span className="profile__required-field">&nbsp;*</span>
                  </Label>
                  <TextInput
                    type="text"
                    hasError={errors.first_name}
                    id="first-name"
                    name="first_name"
                    placeholder="e.g. John"
                    ref={register({ required: "This field is required." })}
                  />
                  {errors.first_name && (
                    <div className="mt-1">
                      <FormNote hasError>{errors.first_name.message}</FormNote>
                    </div>
                  )}
                </Col>
                <Col lg="4">
                  <Label
                    hasError={errors.hasOwnProperty("middle_names")}
                    for="middle-name"
                    className="tp-label"
                  >
                    Middle Name
                  </Label>
                  <TextInput
                    type="text"
                    hasError={errors.middle_names}
                    id="middle-name"
                    name="middle_names"
                    placeholder="e.g. Chris"
                    ref={register}
                  />
                  {errors.middle_names && (
                    <div className="mt-1">
                      <FormNote hasError>
                        {errors.middle_names.message}
                      </FormNote>
                    </div>
                  )}
                </Col>
                <Col lg="4">
                  <Label
                    hasError={errors.hasOwnProperty("last_name")}
                    for="last-name"
                    className="tp-label"
                  >
                    Last Name{" "}
                    <span className="profile__required-field">&nbsp;*</span>
                  </Label>
                  <TextInput
                    type="text"
                    hasError={errors.last_name}
                    id="last-name"
                    name="last_name"
                    placeholder="e.g. Doe"
                    ref={register({ required: "This field is required." })}
                  />
                  {errors.last_name && (
                    <div className="mt-1">
                      <FormNote hasError>{errors.last_name.message}</FormNote>
                    </div>
                  )}
                </Col>
              </Row>
            </li>

            <li className="tp-form-field__item">
              <Label
                hasError={errors.hasOwnProperty("gender")}
                for="gender"
                className="tp-label"
              >
                Gender <span className="profile__required-field">&nbsp;*</span>
              </Label>
              <select
                id="gender"
                name="gender"
                className={`tp-select tp-select--small tp-select--full ${errors.gender &&
                  "tp-select--bad-news"}`}
                ref={register({ required: "This field is required." })}
              >
                <option value="">Select...</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {errors.gender && (
                <div className="mt-1">
                  <FormNote hasError>{errors.gender.message}</FormNote>
                </div>
              )}
            </li>

            <li className="tp-form-field__item">
              <Label for="phone" hasError={errors.hasOwnProperty("phone")}>
                Phone Number{" "}
                <span className="profile__required-field">&nbsp;*</span>
              </Label>
              <Row>
                <Col sm="4" md="6" lg="5">
                  <InputMasked
                    guide={false}
                    mask={masks.phone}
                    type="tel"
                    id="phone"
                    onChange={handlePhoneChange}
                    hasError={errors.phone}
                    placeholder="(713) 834-2650"
                    name="phone"
                  />
                  {errors.phone && (
                    <div className="mb1">
                      <FormNote hasError>{errors.phone.message}</FormNote>
                    </div>
                  )}
                </Col>
                <Col sm="3" md="3" lg="3">
                  <TextInput
                    type="number"
                    min="0"
                    hasError={errors.extension}
                    id="extension"
                    name="extension"
                    placeholder="ext."
                    ref={register({
                      pattern: {
                        value: regex.onlyNumber,
                        message: "Please input a number."
                      },
                      min: {
                        value: 0,
                        message: "Please enter a value between 1 and 12."
                      }
                    })}
                  />
                  {errors.extension && (
                    <div className="mb1">
                      <FormNote hasError>{errors.extension.message}</FormNote>
                    </div>
                  )}
                </Col>
              </Row>
            </li>

            <li className="tp-form-field__item">
              <Label
                className="tp-label"
                hasError={
                  errors.hasOwnProperty("day") ||
                  errors.hasOwnProperty("month") ||
                  errors.hasOwnProperty("year")
                }
              >
                Birthday{" "}
                <span className="profile__required-field">&nbsp;*</span>
              </Label>
              <Row className="birthday__fields">
                <Col sm="2" md="3" lg="2">
                  <Label hasError={errors.hasOwnProperty("month")} for="month">
                    Month
                  </Label>
                  <TextInput
                    type="number"
                    min="1"
                    max="12"
                    hasError={errors.month}
                    id="month"
                    name="month"
                    ref={register({
                      required: "This field is required.",
                      pattern: {
                        value: regex.onlyNumber,
                        message: "Please input a number."
                      },
                      minLength: {
                        value: 1,
                        message: "Please enter a month."
                      },
                      maxLength: {
                        value: 2,
                        message: "Please enter a month."
                      },
                      min: {
                        value: 1,
                        message: "Please enter a value between 1 and 12."
                      },
                      max: {
                        value: 12,
                        message: "Please enter a value between 1 and 12."
                      }
                    })}
                  />
                  {errors.month && (
                    <div className="mt-1">
                      <FormNote hasError>{errors.month.message}</FormNote>
                    </div>
                  )}
                </Col>
                <Col sm="2" md="3" lg="2">
                  <Label hasError={errors.hasOwnProperty("day")} for="day">
                    Day
                  </Label>
                  <TextInput
                    type="number"
                    min="1"
                    max="31"
                    hasError={errors.day}
                    id="day"
                    name="day"
                    ref={register({
                      required: "This field is required.",
                      pattern: {
                        value: regex.onlyNumber,
                        message: "Please input a number."
                      },
                      minLength: {
                        value: 1,
                        message: "Please enter a day of the month."
                      },
                      maxLength: {
                        value: 2,
                        message: "Please enter a day of the month."
                      },
                      min: {
                        value: 1,
                        message: "Please enter a value between 1 and 31."
                      },
                      max: {
                        value: 31,
                        message: "Please enter a value between 1 and 31."
                      }
                    })}
                  />
                  {errors.day && (
                    <div className="mt-1">
                      <FormNote hasError>{errors.day.message}</FormNote>
                    </div>
                  )}
                </Col>
                <Col sm="4" md="4" lg="3">
                  <Label hasError={errors.hasOwnProperty("year")} for="year">
                    Year
                  </Label>
                  <TextInput
                    type="number"
                    min="0"
                    hasError={errors.year}
                    id="year"
                    name="year"
                    ref={register({
                      required: "This field is required.",
                      pattern: {
                        value: regex.onlyNumber,
                        message: "Please input a number."
                      },
                      minLength: {
                        value: 4,
                        message: "Please enter a year."
                      },
                      maxLength: {
                        value: 4,
                        message: "Please enter a year."
                      },
                      min: {
                        value: 1900,
                        message: `Please enter a value between 1905 and ${new Date().getFullYear()}.`
                      },
                      max: {
                        value: new Date().getFullYear(),
                        message: `Please enter a value between 1905 and ${new Date().getFullYear()}.`
                      }
                    })}
                  />
                  {errors.year && (
                    <div className="mt-1">
                      <FormNote hasError>{errors.year.message}</FormNote>
                    </div>
                  )}
                </Col>
              </Row>
            </li>
          </ol>
        </Col>
        <StepTrigger step={currentStep} />
      </Row>
    </form>
  );
}

const Step2 = () => {
  const { currentStep, nextStep, formData } = useContext(stepsContext);
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: formData
  });

  useEffect(() => {
    register({ name: "country_code" });
    register({ name: "county" });
    register({ name: "country" });
  }, [register]);

  if (currentStep !== 2) {
    return null;
  }

  const onSubmit = ({
    address,
    administrative,
    city,
    zip,
    country,
    country_code,
    county,
    year_founded,
    number_employees,
    ...rest
  }) => {
    const userAddress = {
      address_1: address,
      city: city ? city : "",
      country_code: country_code ? country_code : "US",
      county: county ? county : "county",
      name: administrative,
      post_code: zip,
      state: administrative

    };

    const data = {
      addresses: [userAddress],
      year_founded: parseInt(year_founded),
      number_employees: parseInt(number_employees),
      ...rest
    };

    nextStep(data);
  };

  const addressChange = ({
    suggestion: {
      name,
      postcodes,
      city,
      administrative,
      countryCode,
      country,
      county,
      hit
    }
  }) => {
    setValue("address", `${name} ${country || hit.country}`);
    setValue("administrative", administrative);
    setValue("zip", postcodes ? postcodes[0] : "");
    setValue("city", city);
    setValue("country", hit ? hit.country : country);
    setValue("country_code", hit ? hit.country_code : countryCode);
    setValue("county", hit && hit.county ? hit.county[0] : county);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Row>
        <Col md="3"></Col>
        <Col md="6">
          <ol>
            <div className="short-des short-des--1 mt-4">
              <h3>Your Business Info.</h3>
            </div>

            <li className="tp-form-field__item">
              <Label
                hasError={errors.hasOwnProperty("business_name")}
                for="business-name"
                className="tp-label"
              >
                Business Name{" "}
                <span className="profile__required-field">&nbsp;*</span>
              </Label>
              <TextInput
                type="text"
                hasError={errors.business_name}
                id="business-name"
                name="business_name"
                placeholder="e.g. Cleaners"
                ref={register({ required: "This field is required." })}
              />
              {errors.business_name && (
                <div className="mt-1">
                  <FormNote hasError>{errors.business_name.message}</FormNote>
                </div>
              )}
            </li>

            <li className="tp-form-field__item">
              <Label
                hasError={errors.hasOwnProperty("year_founded")}
                className="tp-label"
              >
                Year Founded{" "}
                <span className="profile__required-field">&nbsp;*</span>
              </Label>
              <TextInput
                type="number"
                hasError={errors.year_founded}
                placeholder="e.g. 2002"
                name="year_founded"
                ref={register({
                  required: "This field is required.",
                  pattern: {
                    value: regex.onlyNumber,
                    message: "Please input a number."
                  },
                  minLength: {
                    value: 4,
                    message: "Please enter a year."
                  },
                  maxLength: {
                    value: 4,
                    message: "Please enter a year."
                  },
                  max: {
                    value: new Date().getFullYear(),
                    message: `Please enter a year.`
                  }
                })}
              />
              {errors.year_founded && (
                <div className="mt-1">
                  <FormNote hasError>{errors.year_founded.message}</FormNote>
                </div>
              )}
            </li>

            <li className="tp-form-field__item">
              <Label
                hasError={errors.hasOwnProperty("number_employees")}
                className="tp-label"
              >
                Number of employees{" "}
                <span className="profile__required-field">&nbsp;*</span>
              </Label>

              <TextInput
                type="number"
                min="0"
                placeholder="e.g. 1"
                hasError={errors.number_employees}
                name="number_employees"
                ref={register({
                  required: "This field is required.",
                  pattern: {
                    value: regex.onlyNumber,
                    message: "Please input a number."
                  }
                })}
              />
              {errors.number_employees && (
                <div className="mt-1">
                  <FormNote hasError>
                    {errors.number_employees.message}
                  </FormNote>
                </div>
              )}
            </li>

            <li className="tp-form-field__item">
              <Label
                hasError={errors.hasOwnProperty("business_category")}
                for="business-category"
              >
                Business Category{" "}
                <span className="profile__required-field">&nbsp;*</span>
              </Label>
              <select
                id="business-category"
                name="business_category"
                className={`tp-select tp-select--small tp-select--full ${errors.business_category &&
                  "tp-select--bad-news"}`}
                ref={register({ required: "This field is required." })}
              >
                <option value="">Select...</option>
                <option value="med. spa">Med. Spa</option>
                <option value="waxing">Waxing</option>
                <option value="yoga">Yoga</option>
                <option value="cryotherapy">Cryotherapy</option>
                <option value="oil change">Oil Change</option>
                <option value="dentist">Dentist</option>
                <option value="gym">Gym</option>
              </select>
              {errors.business_category && (
                <div className="mt-1">
                  <FormNote hasError>
                    {errors.business_category.message}
                  </FormNote>
                </div>
              )}
            </li>

            <li className="tp-form-field__item">
              <Label
                id="address"
                hasError={errors.hasOwnProperty("address")}
                className="tp-label"
              >
                Business Address{" "}
                <span className="profile__required-field">&nbsp;*</span>
              </Label>
              <AddressAutocomplete
                hasError={errors.address}
                onChange={addressChange}
                placeholder="Street Address"
                name="address"
                id="address"
                ref={register({ required: "This field is required." })}
              />
              {errors.address && (
                <div className="mt-1">
                  <FormNote hasError>{errors.address.message}</FormNote>
                </div>
              )}
            </li>

            <li className="tp-form-field__item">
              <TextInput
                hasError={errors.administrative}
                type="text"
                placeholder="State / Province / Region"
                name="administrative"
                ref={register({ required: "This field is required." })}
              />
              {errors.administrative && (
                <div className="mt-1">
                  <FormNote hasError>{errors.administrative.message}</FormNote>
                </div>
              )}
            </li>

            <li className="tp-form-field__item">
              <TextInput
                hasError={errors.city}
                type="text"
                placeholder="City"
                name="city"
                ref={register({ required: "This field is required." })}
              />
              {errors.city && (
                <div className="mt-1">
                  <FormNote hasError>{errors.city.message}</FormNote>
                </div>
              )}
            </li>

            <li className="tp-form-field__item">
              <TextInput
                type="text"
                placeholder="Zip Code"
                name="zip"
                hasError={errors.zip}
                ref={register({
                  required: "This field is required.",
                  pattern: {
                    value: regex.onlyNumber,
                    message: "Please input a number."
                  }
                })}
              />
              {errors.zip && (
                <div className="mt-1">
                  <FormNote hasError>{errors.zip.message}</FormNote>
                </div>
              )}
            </li>
          </ol>
        </Col>

        <Col md="3">
          <Card className="newcard mt-5">
            <Card.Body>
              <h4>No business name?</h4>
              <p>Use your personal name</p>
            </Card.Body>
          </Card>
        </Col>
        <StepTrigger step={currentStep} />
      </Row>
    </form>
  );
};

function NewModal() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Open modal
      </Button>
      <ModalDefault
        width="narrow"
        isOpen={isOpen}
        onCloseClick={() => {
          setIsOpen(false);
        }}
        onCloseFinish={() => {
          console.log("ModalDefault onCloseFinish");
        }}
        onOpenFinish={() => {
          console.log("ModalDefault onOpenFinish");
        }}
      >
        <ModalDefaultHeader>
          <ModalDefaultTitle>
            You sure? Customers won't be able to find you.
          </ModalDefaultTitle>
        </ModalDefaultHeader>

        <ModalDefaultContent>
          <div className="steps_modal_info">
            <div className="sei_icon">
              <i className="la la-eye"></i>
            </div>
            <div className="m_content">
              <p>
                <strong>You won't show up.</strong>
              </p>
              <p>Customers searching for pros won't see your profile.</p>
            </div>
          </div>

          <div className="steps_modal_info">
            <div className="sei_icon">
              <i className="la la-dollar"></i>
            </div>
            <div className="m_content">
              <p>
                <strong>No discounts.</strong>
              </p>
              <p>Regular lead prices are rougly $4.</p>
            </div>
          </div>

          <div className="steps_modal_info">
            <div className="sei_icon">
              <i className="la la-ban"></i>
            </div>
            <div className="m_content">
              <p>
                <strong>No direct leads.</strong>
              </p>
              <p>You can only ask to be a customer's backup option.</p>
            </div>
          </div>

          <ButtonRow justify="center">
            <Button theme="secondary">Finish Setup</Button>
            <Button>I'm Sure</Button>
          </ButtonRow>
        </ModalDefaultContent>
      </ModalDefault>
    </React.Fragment>
  );
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "14px"
    },
    invalid: {
      color: "#9e2146"
    }
  }
};

const Step3 = injectStripe(({ stripe }) => {
  const { currentStep, nextStep } = useContext(stepsContext);
  const [leadsAmount, setLeadsAmount] = useState(20);

  if (currentStep !== 3) {
    return null;
  }

  const onSubmit = event => {
    event.preventDefault();
    if (stripe) {
      stripe.createToken().then(payload => console.log("[token]", payload));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }

    const data = {
      weekly_leads_subscription: parseInt(leadsAmount)
    };

    nextStep(data);
  };

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Row>
        <Col md="3"></Col>
        <Col md="6">
          <div className="short-des">
            <h3>Your Billing Info.</h3>
          </div>

          <NewModal />
          <div className="box-holder">
            <div className="short-des">
              <p>Meditation Instruction</p>
              <h4>Where do you want to work?</h4>
              <p>
                Don't worry - this isn't a subscription. You're setting a cap on
                your weekly spending.
              </p>
            </div>
            <div className="tp-grid">
              <div className="tp-col tp-col--6">
                <div className="amountvalue">
                  <h4>$100</h4>
                  <p>
                    Weekly Max <i className="la la-question"></i>
                  </p>
                </div>
              </div>

              <div className="tp-col tp-col--6">
                <div className="amountvalue">
                  <h4>
                    <span>25</span>
                  </h4>
                  <p>
                    estimated leads <i className="la la-question"></i>
                  </p>
                </div>
              </div>

              <div className="tp-col tp-col--12">
                <div className="range_area mt-3">
                  <Slider
                    value={leadsAmount}
                    orientation="horizontal"
                    onChange={setLeadsAmount}
                  />

                  <p>Meditation instructor in your area</p>
                </div>
                <Card body className="labelare">
                  <Row className="align-items-center">
                    <Col sm="4">
                      <Badge pill variant="info" className="mb-2">
                        <i className="la la-check"></i> New Pro discount
                      </Badge>
                    </Col>
                    <Col sm="8">
                      For 4 weeks, get 25% off up to 12 leads. This discount is
                      activated on your first contact.
                    </Col>
                  </Row>
                </Card>
              </div>
            </div>
          </div>

          <div className="box-holder">
            <div className="short-des">
              <div className="tp-grid">
                <div className="tp-col tp-col--8">
                  <h4>Add credit card</h4>
                  <p>All payment info is stored securely</p>
                </div>

                <div className="tp-col tp-col--md-4 ">
                  <div className="text-right icon-grey">
                    <i className="la la-lock"></i>
                  </div>
                </div>
              </div>

              <div className="tp-grid">
                <div className="tp-col tp-col--4">
                  <label className="tp-label">Credit card number</label>
                  <CardNumberElement
                    className="tp-text-input tp-text-input--small"
                    {...CARD_ELEMENT_OPTIONS}
                  />
                </div>

                <div className="tp-col tp-col--3">
                  <label className="tp-label">Expiration</label>
                  <CardExpiryElement
                    className="tp-text-input tp-text-input--small"
                    {...CARD_ELEMENT_OPTIONS}
                  />
                </div>

                <div className="tp-col tp-col--2">
                  <label className="tp-label">CVV</label>
                  <CardCVCElement
                    className="tp-text-input tp-text-input--small"
                    {...CARD_ELEMENT_OPTIONS}
                  />
                </div>

                <div className="tp-col  tp-col--3">
                  <label className="tp-label">Zip Code</label>
                  <input
                    type="text"
                    placeholder="12345"
                    className="tp-text-input tp-text-input--small"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col md="3"></Col>
        <StepTrigger step={currentStep} />
      </Row>
    </form>
  );
});
