import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import useForm from "react-hook-form";
import { Button, FormNote, Label } from "@thumbtack/thumbprint-react";
import { regex, useAlert } from "helpers";
import { userService } from "services";
import { useHistory } from "react-router-dom";

export const ChangePassword = () => {
  const { register, errors, handleSubmit, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const [successAlert, errorAlert] = useAlert();

  const onSubmit = async formData => {
    try {
      setLoading(true);
      const { data } = await userService.changePassword(formData);
      if (data) {
        successAlert("Your password has been successfully changed!");

        push("/login");
      }
    } catch (err) {
      errorAlert(
        err.status === 500
          ? "Oops. Something went wrong!"
          : err.response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="section-default">
      <Container>
        <Row>
          <Col md="12">
            <h1 className="fancyheading text-center">
              Enter your new password
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="box-holder">
              <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
                <ol className="tp-form-fields">
                  <li className="tp-form-field__item">
                    <Label hasError={errors.hasOwnProperty("code")}>
                      Confirmation Code
                    </Label>
                    <input
                      type="text"
                      ref={register({
                        required: "This field is required."
                      })}
                      name="code"
                      className={`tp-text-input tp-text-input--small ${errors.code &&
                        "tp-text-input--bad-news"}`}
                    />
                    {errors.code && (
                      <div className="mb1">
                        <FormNote hasError>{errors.code.message}</FormNote>
                      </div>
                    )}
                  </li>
                  <li className="tp-form-field__item">
                    <Label hasError={errors.hasOwnProperty("email")}>
                      Email Address
                    </Label>
                    <input
                      type="email"
                      className={`tp-text-input tp-text-input--small ${errors.email &&
                        "tp-text-input--bad-news"}`}
                      ref={register({
                        required: "This field is required.",
                        pattern: {
                          value: regex.email,
                          message: "Please enter an email address."
                        }
                      })}
                      name="email"
                    />
                    {errors.email && (
                      <div className="mb1">
                        <FormNote hasError>{errors.email.message}</FormNote>
                      </div>
                    )}
                  </li>

                  <li className="tp-form-field__item">
                    <Label hasError={errors.hasOwnProperty("new_password")}>
                      New Password
                    </Label>
                    <input
                      type="password"
                      className={`tp-text-input tp-text-input--small ${errors.new_password &&
                        "tp-text-input--bad-news"}`}
                      ref={register({
                        required: "This field is required.",
                        pattern: {
                          value: regex.password,
                          message:
                            "The password must contain at least 1 uppercase letter and 1 special character."
                        }
                      })}
                      name="new_password"
                    />
                    {errors.new_password && (
                      <div className="mb1">
                        <FormNote hasError>
                          {errors.new_password.message}
                        </FormNote>
                      </div>
                    )}
                  </li>

                  <li className="tp-form-field__item">
                    <Label hasError={errors.hasOwnProperty("confirmPassword")}>
                      Confirm New Password
                    </Label>
                    <input
                      type="password"
                      className={`tp-text-input tp-text-input--small ${errors.confirmPassword &&
                        "tp-text-input--bad-news"}`}
                      ref={register({
                        required: "This field is required.",
                        validate: value => {
                          const { new_password } = getValues();
                          return (
                            new_password === value || "Passwords do not match."
                          );
                        }
                      })}
                      name="confirmPassword"
                    />
                    {errors.confirmPassword && (
                      <div className="mb1">
                        <FormNote hasError>
                          {errors.confirmPassword.message}
                        </FormNote>
                      </div>
                    )}
                  </li>

                  <li className="tp-form-field__item">
                    <p className="mt3">
                      Enter the email address associated with your account, and
                      we’ll email you a link to reset your password.
                    </p>
                  </li>
                  <li>
                    <Button type="submit" isLoading={loading} width="full">
                      Change Password
                    </Button>
                  </li>
                </ol>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
