import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import { userActions, alertActions } from "actions";
import { userService } from "services";
import { userConstants } from "../constants";
import { push } from "connected-react-router";

function* login({ payload }) {
  try {
    const { data } = yield call(userService.login, payload);

    yield put(userActions.loginSuccess(data));
    yield put(push("/dashboard"));
  } catch (err) {
    yield put(userActions.loginError(err));
    yield put(alertActions.error(err.response.data.message));
  }
}

function* logout() {
  try {
    yield call(userService.logout);
    yield put(userActions.logoutSuccess());
    yield put(push("/"));
  } catch (err) {
    yield put(userActions.logoutError(err));
    yield put(alertActions.error(err.response.data.message));
  }
}

function* watchLoginRequest() {
  yield takeLatest(userConstants.LOGIN_REQUEST, login);
}

function* watchLogoutRequest() {
  yield takeLatest(userConstants.LOGOUT_REQUEST, logout);
}

export default function* userSaga() {
  yield all([fork(watchLoginRequest), fork(watchLogoutRequest)]);
}
