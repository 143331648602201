import React from "react";
import { Row, Col, Container } from "react-bootstrap";
export const Join = () => (
  <section className="section-default min-vh-100">
    <Container>
      <Row className="justify-content-center ">
        <Col md="6">
          <div className="short-des">
            <h4>Let's starte with the basics.</h4>
            <p>
              We use this info to helop ensure trust and safety for Thubtrack
              customers{" "}
            </p>
          </div>
          <form>
            <div className="box-holder">
              <ol className="tp-form-fields">
                <li className="tp-form-field__item">
                  <div className="tp-grid">
                    <div className="tp-col tp-col--6">
                      <label className="tp-label">First Name</label>
                      <input
                        type="text"
                        className="tp-text-input tp-text-input--small"
                      />
                    </div>

                    <div className="tp-col tp-col--6">
                      <label className="tp-label">Last Name</label>
                      <input
                        type="text"
                        className="tp-text-input tp-text-input--small"
                      />
                    </div>
                    <div className="tp-col tp-col--12">
                      <p className="small-text mt-2">
                        Enter your First Name & Last Name
                      </p>
                    </div>
                  </div>
                </li>

                <li className="tp-form-field__item">
                  <div className="tp-grid">
                    <div className="tp-col tp-col--12">
                      <label className="tp-label">Date of Birth</label>
                    </div>

                    <div className="tp-col tp-col--4">
                      <select className="tp-select tp-select--small tp-select--full">
                        <option>Month</option>
                      </select>
                    </div>

                    <div className="tp-col tp-col--4">
                      <select className="tp-select tp-select--small tp-select--full">
                        <option>Month</option>
                      </select>
                    </div>

                    <div className="tp-col tp-col--4">
                      <select className="tp-select tp-select--small tp-select--full">
                        <option>Month</option>
                      </select>
                    </div>
                  </div>
                </li>

                <li className="tp-form-field__item">
                  <div className="tp-grid">
                    <div className="tp-col tp-col--12 mb-2">
                      <label className="tp-label">Home Address</label>
                    </div>

                    <div className="tp-col tp-col--12 mb-2">
                      <input
                        type="text"
                        className="tp-text-input tp-text-input--small"
                        placeholder="Street Address"
                      />
                    </div>

                    <div className="tp-col tp-col--12 mb-2">
                      <input
                        type="text"
                        className="tp-text-input tp-text-input--small"
                        placeholder="Appartment"
                      />
                    </div>
                    <div className="tp-col tp-col--6 mb-2">
                      <select className="tp-select tp-select--small tp-select--full">
                        <option>State</option>
                      </select>
                    </div>
                    <div className="tp-col tp-col--6 mb-2">
                      <input
                        type="text"
                        className="tp-text-input tp-text-input--small"
                        placeholder="Zip Code"
                      />
                    </div>
                    <div className="tp-col tp-col--12">
                      <p className="small-text">
                        Make Sure everything entered is right before submitting
                      </p>
                    </div>
                  </div>
                </li>
              </ol>
            </div>

            <p className="text-right">
              <input type="submit" value="submit" className="tp-button" />
            </p>
          </form>
        </Col>
      </Row>
    </Container>
  </section>
);
