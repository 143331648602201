import React from "react";
import { Row, Col, Badge } from "react-bootstrap";

const LeadCard = ({ campaign_id, business_name, addresses }) => {
  const { post_code, city, state } = addresses[0];

  return (
    <Col md="12">
      <div className="project_wrapper">
        <div className="project_header">
          <Row>
            <Col md="8">
              <h4 className="project_title">
                <strong>{`${campaign_id}`}</strong> Contacted{" "}
                <strong>{business_name}</strong>
              </h4>
              <p className="project_time">13m ago. 1 pro responded</p>
            </Col>
            <Col md="4">
              <div className="tp-button-row tp-button-row--right">
                <button className="tp-button pass_btn tp-button--clear tp-button--small">
                  Pass
                </button>
                <button className="tp-button tp-button--small">
                  View Details
                </button>
              </div>
            </Col>
          </Row>
        </div>

        <div className="project_container">
          <ul className="project_ses">
            <li>
              <div className="icosn">
                <i className="la la-map-marker"></i>
              </div>
              <div className="text_content">
                <p>{`${city}, ${state} ${post_code}`}</p>
              </div>
            </li>

            <li>
              <div className="icosn">
                <i className="la la-calendar"></i>
              </div>
              <div className="text_content">
                <p>Date: sept 20</p>
                <p>Time: Morning (9am - 12pm)</p>
                <p>Allow pro to suggest other dates</p>
              </div>
            </li>

            <li>
              <div className="icosn">
                <i className="la la-info-circle"></i>
              </div>
              <div className="text_content">
                <Badge pill variant="light" className="labels">
                  5 bedrooms
                </Badge>

                <Badge pill variant="light" className="labels">
                  4 bedrooms
                </Badge>

                <Badge pill variant="light" className="labels">
                  Standard Cleaning
                </Badge>

                <Badge pill variant="light" className="labels">
                  No pets in home
                </Badge>

                <Badge pill variant="light" className="labels">
                  Every 2 weeks
                </Badge>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Col>
  );
};

export default LeadCard;
