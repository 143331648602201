import React from "react";
import "./Home.css";
import SimpleSlider from "../components/Carousel";
import { Row, Col, Container, Jumbotron, Card } from "react-bootstrap";
const LANDING_PAGE_URL= "https://app.localcall.io";

export const Home = () => (
  <React.Fragment>
    <Jumbotron className="hero">
      <div className="clouds-hero" />
      <Container>
        <Row className="justify-content-center">
          <Col lg={7}>
            <h1>Double, Triple, or Even Quadruple Your Business...For Free?</h1>
            <h2>We can make your phone ring fast with our killer service.</h2>
            <div className="button-area">
              <p>
                <a className="purple_btn" href={LANDING_PAGE_URL}>     
                  Get Leads Now
                </a>
              </p>
              <p>
                <a className="liner-btn" href={LANDING_PAGE_URL}>
                  <i className="la la-play-circle" /> Watch The video
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Jumbotron>

    <section className="section-media" />

    <section className="section-information">
      <Container>
        <Row className="justify-content-center">
          <Col md={7}>
            <h2>You Pay Nothing Out of Pocket Ever.</h2>
            <p>
              Here is why.- LocalCall is a lead generation company that
              specializes in helping local businesses like yours to get 20-50
              more leads weekly or monthly.
            </p>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="instant">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h2>Instant Everything</h2>
            <p>
              Janet, our charming artificial intelligence bot, will craft the
              perfect Marketing offer for you. It couldn’t be easier, or faster.
            </p>
            <p>
              <a className="liner-btn" href=".#">
                <i className="la la-play-circle" /> See our promise to you in
                action
              </a>
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <div className="arrow-left">
              <strong>90 Seconds</strong>
              <br />
              To get setup
            </div>
            <div className="show-mobile">
              <div className="info1">
                <strong>90 Seconds</strong>
                <br />
                To get setup
              </div>
              <div className="info2">
                <strong>90 Seconds</strong>
                <br />
                To get setup
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="mobile-box" />
          </Col>
          <Col lg={4}>
            <div className="arrow-right">
              <strong>12 hours</strong>
              <br />
              To start getting new lead customers
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="testimonial-section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12}>
            <div className="stars">
              <i className="la la-star" />
              <i className="la la-star" />
              <i className="la la-star" />
              <i className="la la-star" />
              <i className="la la-star" />
            </div>
            <h2 className="large-text text-center">
              The #1 Rated Local Marketing Company in America!
            </h2>
            <p className="content-big text-center">
              LocalCall is scored highest on BBB, Google, Supermoney and more...
            </p>

            <div className="blog_sldeo">
              <SimpleSlider />
            </div>

            <div className="quoteation">
              <h4>
                "LocalCall takes a flat fee and treats the rest of the money as
                yours, not ours."
              </h4>
              <p className="brand_auth">
                <img src={require("assets/images/inc.jpg")} alt="Brand" />{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="section-default">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12} className="mb-5">
            <h2 className="large-text text-center">
              Killer Prices,<br />
              Monthly Subscription
            </h2>
          </Col>

          <Col lg={4}>
            <Card className="grey-card">
              <Card.Body>
                <h4>
                  Up to 20<br />Leads
                </h4>
                <ins>MONTHLY, FROM</ins>
                <p className="price">
                  $45<div className="small_print">(each)</div>
                </p>
                <a className="purple_btn_full" href={LANDING_PAGE_URL}>
                  Get Leads Now
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4}>
            <Card className="grey-card">
              <Card.Body>
                <h4>
                  50+<br />Leads
                </h4>
                <ins>MONTHLY, FROM</ins>
                <p className="price">
                  $35<div className="small_print">(each)</div>
                </p>
                <a className="purple_btn_full" href={LANDING_PAGE_URL}>
                  Get Leads Now
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={12}>
            <p className="text-center mt-5">
              <strong>Already have a Lead Generation Agency? </strong> We'll
              take care of the switching hassle.
            </p>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="section-default">
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className="large-text text-center">How LocalCall Works</h2>
            <p className="content-big text-center">
              LocalCall reverses the traditional marketing model. We treat your
              leads as if it's ours. With LocalCall, everything becomes simple
              and transparent. We take a flat fee per lead, we setup all
              marketing super fast, and <span>give back to you </span>what’s
              left.*
            </p>
          </Col>

          <Col lg={12}>
            <div className="box-group">
              <div className="box">
                <div className="box1" />
                <p className="mt-2">
                  A <strong>flat fee</strong> to run everything
                </p>
              </div>

              <div className="box">
                <div className="box2" />
                <p className="mt-2">
                  You pay Leads as you go <strong>super fast</strong>
                </p>
              </div>

              <div className="box">
                <div className="box3" />
                <p className="mt-2">
                  If there's money leftover, we{" "}
                  <strong>give it back to you</strong>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>
);
