export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT_REQUEST: "USERS_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "USERS_LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "USERS_LOGOUT_FAILURE",

  CONFIRM_REQUEST: "USERS_CONFIRM_REQUEST",
  CONFIRM_SUCCESS: "USERS_CONFIRM_SUCCESS",
  CONFIRM_FAILURE: "USERS_CONFIRM_FAILURE"
};
