import React from "react";
import "./Job.css";
import { Row, Col, Container, Card } from "react-bootstrap";
import { StarRating } from "@thumbtack/thumbprint-react";
export const Job = () => (
  <React.Fragment>
    <Container fluid>
      <Row>
        {/*Left Panel Start*/}
        <Col md="4">
          <div className="map_box d-flex justify-content-center">
            <img src={require("assets/images/map.jpg")} alt="" />
            <div className="cirular-box">AW</div>
          </div>
          <div className="prf_name">
            <h1>Annette W.</h1>
            <p>Richmond, TX 77469</p>
          </div>

          <div className="pf_info_bx">
            <Row>
              <Col md="4">
                <div className="d_info border-right">
                  <h4>3</h4>
                  <p>Pros contacted</p>
                </div>
              </Col>
              <Col md="4">
                <div className="d_info border-right">
                  <h4>3</h4>
                  <p>Pros responded</p>
                </div>
              </Col>
              <Col md="4">
                <div className="d_info">
                  <h4>4h 32m</h4>
                  <p>avg pro response time</p>
                </div>
              </Col>

              <Col md="12">
                <div className="d_info">
                  <p>
                    You will only pay if customer responds. See how much:{" "}
                    <a href="#/">Learn More</a>
                  </p>
                </div>
                <hr />
              </Col>
              <Col md="12">
                <ul className="jb_in">
                  <li>
                    <h5>Job Type</h5>
                    <p>House Cleaning</p>
                  </li>

                  <li>
                    <h5>Number of bedrooms</h5>
                    <p>4 bedrooms</p>
                  </li>

                  <li>
                    <h5>Number of bathrooms</h5>
                    <p>4 bathrooms</p>
                  </li>

                  <li>
                    <h5>Cleaning Type</h5>
                    <p>Deep Cleaning</p>
                  </li>

                  <li>
                    <h5>Extra Services</h5>
                    <p>
                      Window Cleaning (interior), Fridge Cleaning, Oven Cleaning
                    </p>
                  </li>

                  <li>
                    <h5>Pets</h5>
                    <p>Pets in home</p>
                  </li>
                  <li>
                    <h5>Frequenty</h5>
                    <p>Once a month</p>
                  </li>

                  <li>
                    <h5>Zip</h5>
                    <p>711303</p>
                  </li>

                  <li>
                    <h5>Message</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </li>

                  <li>
                    <h5>Scheduling Prefernces</h5>
                    <p>Dates: Sept 17, Sept 18, Sept 20,Sept 24</p>
                    <p>Times: Afternoon (12pm-3pm), Late Afternoon (3pm-6pm)</p>
                    <p>Allow pro to suggest other dates</p>
                  </li>

                  <li>
                    <h5>Phone Number</h5>
                    <p>Not Available</p>
                  </li>

                  <li>
                    <h5>Job Expires</h5>
                    <p>At 5:15pm on 09-17-19 </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Col>
        {/*Left Panel End*/}
        {/*Right Panel Start*/}
        <Col md="8">
          <div className="right_side_bar">
            <h4 className="title">Your Profile</h4>
            <Card>
              <Card.Body>
                <div className="av_profile">
                  <div className="image_hold">
                    <img src={require("assets/images/map.jpg")} alt="" />
                  </div>
                  <div className="profile_nme">
                    <h4>Mediactions</h4>
                    <p>
                      <StarRating rating={4.5} size="medium" />
                    </p>
                  </div>
                </div>

                <div className="intro">
                  <p>
                    <strong>Introduction:</strong> It is a long established fact
                    that a reader will be distracted by the readable content of
                    a page when looking at its layout.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="right_side_bar">
            <h4 className="title">Your Price</h4>
            <Card>
              <Card.Body>
                <div className="setion">
                  <Row className="align-items-center">
                    <Col xs="9">
                      <p className="mb-2">
                        <strong>Estimated for this job</strong>
                      </p>
                      <select className="tp-select tp-select--small">
                        <option>Fixed Price</option>
                      </select>
                    </Col>

                    <Col xs="3">
                      <p className="mb-2"></p>
                      <input
                        type="text"
                        className="tp-text-input tp-text-input--small"
                        placeholder="$ 0"
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
            <div className="tp-button-row mt-3 tp-button-row--right">
              <button className="tp-button tp-button--small">Send Quote</button>
            </div>
          </div>
        </Col>
        {/*Right Panel End*/}
      </Row>
    </Container>
  </React.Fragment>
);
