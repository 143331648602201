import React from "react";
import { Checkbox } from "@thumbtack/thumbprint-react";
export class Weekdays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isChecked: undefined,
      description: props.children
    };

    this.inputText = React.createRef();
    this.inputText2 = React.createRef();
    this.onChange = this.onChange.bind(this);
  }

  onChange(isChecked) {
    this.setState({
      isChecked: true,
      isEditing: true
    });
  }

  save() {
    const inputValue =
      this.inputText.current.value + " - " + this.inputText2.current.value;
    this.setState({
      isEditing: false,
      description: inputValue
    });
  }

  renderEdit() {
    const isEditing = this.state.isEditing;
    const props = this.props;
    if (isEditing === true) {
      return (
        <React.Fragment>
          <select
            defaultValue={props.children}
            ref={this.inputText}
            className="tp-select--small tp-select"
          >
            <option>1 am</option>
            <option>2 am</option>
            <option>3 am</option>
            <option>4 am</option>
            <option>5 am</option>
            <option>6 am</option>
            <option>7 am</option>
            <option>8 am</option>
            <option>9 am</option>
            <option>10 am</option>
            <option>11 am</option>
            <option>12 am</option>
          </select>
          to
          <select
            defaultValue={props.children}
            ref={this.inputText2}
            className="tp-select--small tp-select"
          >
            <option>1 pm</option>
            <option>2 pm</option>
            <option>3 pm</option>
            <option>4 pm</option>
            <option>5 pm</option>
            <option>6 pm</option>
            <option>7 pm</option>
            <option>8 pm</option>
            <option>9 pm</option>
            <option>10 pm</option>
            <option>11 pm</option>
            <option>12 pm</option>
          </select>
          <button
            onClick={() => this.save()}
            className="tp-button tp-button--clear tp-button--small"
          >
            {" "}
            Done
          </button>
        </React.Fragment>
      );
    } else {
      return <p>{this.state.description}</p>;
    }
  }

  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <li className="tp-form-field__item ">
          <div className="tp-grid d-flex align-items-center">
            <div className="tp-col tp-col--4 ss-checkbox-label">
              <Checkbox
                isChecked={this.state.isChecked}
                onChange={this.onChange}
              >
                {props.title}
              </Checkbox>
            </div>
            <div className="tp-col tp-col--8 ss-checkbox-label">
              {this.renderEdit()}
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  }
}
