import { userConstants } from "../constants";
import jwtDecode from "jwt-decode";
import produce from "immer";

export const authentication = produce((draft = {}, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      draft.loading = true;
      break;
    case userConstants.LOGIN_SUCCESS:
      draft.loading = false;
      draft.loggedIn = true;
      const userInfo = jwtDecode(action.payload.token);
      draft.user = { ...action.payload, ...userInfo };
      break;
    case userConstants.LOGIN_FAILURE:
      draft.loading = false;
      draft.error = action.error;
      break;
    case userConstants.LOGOUT_REQUEST:
      draft.loading = true;
      break;
    case userConstants.LOGOUT_SUCCESS:
      return {};
    case userConstants.LOGOUT_FAILURE:
      draft.loading = false;
      draft.error = action.error;
      draft.loggedIn = false;
      draft.user = undefined;
      break;
    case userConstants.CONFIRM_REQUEST:
      draft.loading = true;
      break;
    case userConstants.CONFIRM_SUCCESS:
      return {};
    case userConstants.CONFIRM_FAILURE:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
      return draft;
  }
});
