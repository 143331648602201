import React from "react";
import { Row, Col, Container } from "react-bootstrap";
export const Privacy = () => (
  <section className="section-default min-vh-100">
    <Container>
      <Row className="justify-content-center ">
        <Col md="12">
          <div className="short-des">
            <p>
              We use this info to help ensure trust and safety for LocalCall
              Clients
            </p>
          </div>

          <div className="box-holder">
            <b>PRIVACY POLICY</b>
            <br />
            <br />
            <p>
              LocalCall, Inc. (“LocalCall”, “We”, “Us” or “Our”) owns and
              operates a proprietary call intelligence technology platform
              (“LocalCall Platform”). Using unique phone numbers with
              personalization components combined with database management,
              LocalCall provides networks, advertisers, agencies, publishers
              (directly and indirectly “Clients”), and consumers with marketing
              automation services (the “Services”).
            </p>
            <p>
              Privacy is important to LocalCall and any information that is
              collected or used is limited to what is necessary to create and
              maintain innovative and reliable Services without compromising the
              individual privacy of users. This policy covers data collected
              through LocalCall’s corporate website (<a href="https://www.LocalCall.io/">
                www.LocalCall.io
              </a>) and data collected through the LocalCall Platform. It also
              describes the choices available to you regarding the use of, your
              access to, and how to update and correct your personal
              information. The use of information collected through the
              LocalCall Platform shall be limited to the purpose of providing
              the service for which the Client has engaged LocalCall, Inc.
            </p>
            <p>
              Throughout this Policy “personal information” means user and/or
              Client name, address, phone number, email address and IP address
              or any information or combination of information that can be used
              to identify, contact, or locate a discrete individual.
            </p>
            <p>
              <strong>EU – U.S. Privacy Shield</strong>
            </p>
            <p>
              LocalCall participates in and has certified its compliance with
              the EU-U.S. Privacy Shield Framework.&nbsp;LocalCall is committed
              to subjecting all personal data received from European Union (EU)
              member countries, in reliance on the Privacy Shield Framework, to
              the Framework’s applicable Principles. To learn more about the
              Privacy Shield Framework, visit the U.S. Department of Commerce’s
              Privacy Shield List:&nbsp;<a href="https://www.privacyshield.gov/list">
                https://www.privacyshield.gov/list
              </a>
            </p>
            <p>
              LocalCall is responsible for the processing of personal data it
              receives, under the Privacy Shield Framework, and subsequently
              transfers to a third party acting as an agent on its behalf in
              accordance with LocalCall’s contractual obligations with its
              customers.. LocalCall complies with the Privacy Shield Principles
              for all onward transfers of personal data from the EU, including
              the onward transfer liability provisions.
            </p>
            <p>
              With respect to personal data received or transferred pursuant to
              the Privacy Shield Framework, LocalCall is subject to the
              regulatory enforcement powers of the U.S. Federal Trade
              Commission.&nbsp;In certain situations, LocalCall may be required
              to disclose personal data in response to lawful requests by public
              authorities, including to meet national security or law
              enforcement requirements.
            </p>
            <p>
              If you have an unresolved privacy or data use concern that we have
              not addressed satisfactorily, please contact our U.S.-based third
              party dispute resolution provider (free of charge) at&nbsp;<a href="https://feedback-form.truste.com/watchdog/request">
                https://feedback-form.truste.com/watchdog/request
              </a>.
            </p>
            <p>
              Under certain conditions, more fully described on the Privacy
              Shield website&nbsp;<a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">
                https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint
              </a>, you may invoke binding arbitration when other dispute
              resolution procedures have been exhausted.
            </p>
            <p>
              <strong>CONSUMER PRIVACY:</strong>
            </p>
            <p>What information does LocalCall collect from consumers?</p>
            <p>
              <strong>Web Services:</strong>
              <br />
              In general, when a consumer accesses the LocalCall website or
              interfaces with the LocalCall
            </p>
            <p>
              Platform by visiting the website or by calling a unique phone
              number associated with one of our Clients, LocalCall collects that
              consumer’s personal information if it is made available by the
              consumer or by a third party. LocalCall will not knowingly collect
              personal information from children under the age of eighteen.
            </p>
            <p>
              LocalCall or its Clients may also set up order forms,
              questionnaires or other mechanisms through which a consumer may be
              asked to provide certain information either online or via
              telephone or other communication medium. LocalCall will collect
              such consumer-supplied personal information. If, however, such
              communications are initiated by one of LocalCall’s Clients and the
              Client collects the information, LocalCall shall not be
              responsible for that information, though LocalCall does endeavour
              to ensure that its Clients comply with privacy regulations.
              Participation in any of these communications is purely voluntary;
              any consumer not wanting to participate or provide information may
              decline or terminate contact by navigating away from the web page
              or hanging up the phone.
            </p>
            <p>
              When a consumer responds to or views an internet advertisement
              served by an LocalCall Client or visits an LocalCall website,
              LocalCall sends one or more cookies to the consumer’s computer to
              uniquely identify that consumer’s browser. As is common practice
              of doing business on the Internet, LocalCall uses cookies to
              improve the quality of the Services by storing user preferences
              and tracking user trends and also to determine how the Services
              are used and navigated. Cookies that LocalCall manages on third
              party websites only collect data in the aggregate. While many
              cookies are automatically deleted at the end of a web session,
              LocalCall may use persistent cookies that remain on a specific
              browser after termination of a session. Any consumer may set their
              browser to refuse cookies or automatically clear cookies after
              termination of a session.
            </p>
            <p>
              If you want to learn more about cookies, or how to control or
              delete them please visit&nbsp;<a href="https://www.aboutcookies.org/">
                https://www.aboutcookies.org
              </a>&nbsp;for detailed guidance.
            </p>
            <p>
              <strong>Phone Services:</strong>
              <br />
              LocalCall provides its Clients with unique telephone numbers to be
              used on websites, emails, mobile or offline promotions. This
              enables LocalCall to both determine what the consumer was viewing
              when making a phone call and also personalize the experience for
              that consumer. LocalCall’s phone servers automatically collect the
              caller ID results when a consumer calls a number. LocalCall may
              also collect usage information from third-party databases,
              including the name, address and/or geographic location of the
              caller when such information is available to
              analyze&nbsp;information to optimize its marketing and product
              development initiatives in order to provide a better user and
              Client experience.
            </p>
            <p>
              In addition, LocalCall’s phone servers use software that may be
              employed on behalf of a Client to record calls. This recorded
              information may be evaluated in order to determine the quality of
              that call from the Client’s perspective and for training or other
              purposes. The recorded information also enables LocalCall to
              identify high-quality calls and collect additional usage data
              which help improve the Services and boost sales for Clients.
            </p>
            <p>
              LocalCall may also use third-party services or technology for the
              transcription or analysis of such recorded information. In the
              event that LocalCall records a call on behalf of a Client,
              LocalCall will ensure that call participants are advised of the
              existence and intent of the recording in a manner consistent with
              applicable state and federal laws. LocalCall is not responsible in
              any way for the accuracy or validity of the content of any such
              recordings, and takes no steps to evaluate such accuracy or
              validity.
            </p>
            <p>
              While LocalCall strongly encourages Clients to adopt responsible
              approaches to marketing, LocalCall is not responsible for the
              information practices of Clients or their partners. The
              collection, use, and disclosure of information by Clients and
              their partners are subject to their respective privacy policies,
              which may differ from LocalCall’s policy.
            </p>
            <p>
              <strong>
                What are LocalCall’s reasons for collecting usage information?
              </strong>
            </p>
            <p>
              LocalCall collects usage information when an internet user clicks
              on a link, creates a lead, initiates a phone call, receives a
              phone call, makes a purchase on a Client’s website, sends
              LocalCall or a Client a text message, receives a text message from
              LocalCall or a Client or makes an inquiry or a purchase during a
              phone call to a Client. Primarily, LocalCall collects this
              information for the purpose of attributing calls back to the
              originating ads or paying commissions to third parties and also to
              personalize the internet or phone user’s experience. If the
              internet user revisits the Client’s site directly or if the phone
              user calls the Client using the unique phone number again,
              LocalCall is able to continue to track the user’s activities on
              the Client’s site and compensate third parties that referred the
              user. LocalCall reserves the right to retain all data as long as
              may be reasonably necessary for accounting and audit purposes.
            </p>
            <p>
              Like most standard Website servers, LocalCall uses log files to
              collect Internet protocol (IP) addresses, browser type, Internet
              service provider (ISP), referring/exit pages, the files viewed on
              our site (e.g., HTML pages, graphics, etc.), operating system,
              platform type, date/time stamp and/or clickstream data. LocalCall
              may use the information to analyze site usage, administer the
              site, understand usage and navigation trends in aggregate, and
              determine the relevance of content. LocalCall may link this
              automatically collected data to other information it collects
              about consumer.
            </p>
            <p>
              LocalCall also may develop predictive statistical user profiles of
              generic consumers’ needs and interests by collecting both internet
              browsing and online purchase data and phone user data. For
              improved audience targeting, some LocalCall services may use
              marketing technology to allow advertisers to supplement
              non-personally-identifying demographic information, such as age,
              gender, area code, or ZIP code, to the non-identifiable profile
              data.
            </p>
            <p>
              LocalCall will share this information only as described herein.
              LocalCall will retain this personal information for as long as the
              Client is active&nbsp;or as needed to provide the Services.
              LocalCall may also retain and use personal information as
              necessary to comply with any legal obligations, resolve disputes
              and/or enforce agreements.
            </p>
            <p>
              <strong>
                Does LocalCall share usage information with others?
              </strong>
            </p>
            <p>
              LocalCall does not share consumers’ personal information with
              companies or individuals outside of LocalCall except as described
              below:
            </p>
            <ul>
              <li>
                LocalCall obtains consumer consent by requiring consumers to
                opt-in prior to LocalCall sharing information with LocalCall’s
                trusted third parties.
              </li>
              <li>
                LocalCall may provide information to its subsidiaries,
                affiliated companies or other trusted businesses or persons for
                the purpose of processing personal information on LocalCall’s
                behalf to improve and optimize the Services. LocalCall requires
                these parties to agree to process such information only as
                necessary to provide LocalCall service, based on LocalCall’s
                instructions and in compliance with this privacy policy, privacy
                regulations, and other appropriate confidentiality and security
                measures.
              </li>
              <li>
                LocalCall provides such information to its Clients to enable
                them to better provide consumers with information about products
                or services about which the consumer may be inquiring.
              </li>
              <li>
                LocalCall may share personal information with its affiliates and
                other third parties to provide consumers with goods or services
                requested by them.
              </li>
              <li>
                LocalCall may share personal information in connection with the
                sale, merger, consolidation, change in control, transfer of
                substantial assets, reorganization or liquidation of LocalCall.
                In this case, consumers will be notified via a prominent notice
                on our website of any change in ownership or uses of consumer’s
                personal information, as well as any choices consumers may have
                regarding their personal information.
              </li>
              <li>
                LocalCall may release personal information when it believes such
                release is appropriate to respond to a subpoena, bankruptcy
                proceedings, or similar legal process or to otherwise comply
                with the law, enforce or apply LocalCall’s terms of service and
                other agreements or protect the rights, property or safety of
                LocalCall or others. This includes exchanging information with
                other companies and organizations for fraud protection.
              </li>
            </ul>
            <p>
              <strong>Can a consumer opt-out of providing information?</strong>
            </p>
            <p>
              For consumers accessing the LocalCall Platform through the
              internet, some of its cookies may have an “opt-out” option. This
              process does not remove the LocalCall cookie from a consumer’s
              system; it simply replaces the cookie with an opt-out cookie that
              does not contain any unique identifiers. Some of LocalCall’s
              cookies do not have an “opt-out” option, but consumers always have
              the option of blocking the use of cookies by changing a setting in
              their internet browser. LocalCall does not maintain a process for
              an individual consumer to opt-out of an LocalCall ad serving
              cookie. However, any consumer may set their browser to refuse
              cookies or automatically clear cookies after termination of a
              session. If you reject cookies, you may still use our website, but
              your ability to use some features or areas of our website may be
              limited.
            </p>
            <p>
              With regard to phone services, consumers are able to submit their
              phone numbers to “do-not-call” registries. LocalCall will use its
              best efforts to observe do-not-call registries in any call
              campaign it performs or facilitates. However, consumers who have
              opted-in to receive such messages, either with a Client or with
              LocalCall, shall not be subject to any do-not-call restriction.
            </p>
            <p>
              LocalCall also partners with third-party ad networks to either
              display advertising on the LocalCall website or to manage its
              advertising on other sites. These ad network partner(s) may use
              cookies and web beacons to collect non-personal information about
              a user’s activities on websites to provide the user with targeted
              advertising based upon its interests. If a user wishes to not have
              this information used for the purpose of being served targeted
              ads, a user may opt-out by clicking&nbsp;<a href="https://preferences.truste.com/truste/">
                <em>here</em>
              </a>&nbsp;(or if located in the European Union click&nbsp;<a href="https://www.youronlinechoices.eu/">
                <em>here</em>
              </a>). Please note this does not opt a user out of being served
              all advertising. The user will continue to receive generic ads.
            </p>
            <p>
              As mentioned above, we may use web beacons. Web beacons are tiny
              graphics with a unique identifier, similar in function to cookies,
              and are used to track the online movements of internet users.
              Unlike cookies, which are stored on a user’s computer hard drive,
              web beacons are embedded invisibly on websites. We tie the
              information gathered by web beacons to personal information we
              otherwise collect, and use them in our HTML-based emails to learn
              which emails have been opened by recipients. This allows us to
              gauge the effectiveness of our Clients’ marketing campaigns as
              well as to operate and improve the Services. You can opt-out of
              these emails by following the unsubscribe instructions within such
              marketing emails.
            </p>
            <p>
              <strong>
                Can a consumer access the personal information acquired by
                LocalCall?
              </strong>
            </p>
            <p>
              Upon request LocalCall will provide you with information about
              whether we hold or process on behalf of a third party, any of your
              personal information. To request this information please, contact
              us at&nbsp;<a href="mailto:legal@LocalCall.io">
                legal@LocalCall.io
              </a>.
            </p>
            <p>
              LocalCall’s Services are not directed toward individual consumers
              however if a consumer would like the personal information
              LocalCall holds about him/her corrected, updated or deleted,
              please contact&nbsp;<a href="mailto:legal@LocalCall.io">
                legal@LocalCall.io
              </a>. We will respond to your request within a reasonable
              timeframe.
            </p>
            <p>
              <strong>CLIENT PRIVACY</strong>
            </p>
            <p>
              <strong>
                What information does LocalCall collect from its Clients?
              </strong>
            </p>
            <p>
              LocalCall collects personal information from all of our Clients,
              which include businesses, networks, agencies, advertisers and
              publishers. The collection of this information is part of
              LocalCall’s normal business practices. Advertisers, businesses or
              their representatives will be asked to provide personal
              information through the sign up and registration process either
              directly by LocalCall or indirectly through a network or agency
              partner. This information may include name, address, phone number,
              hours of operation, coverage areas and email address.
            </p>
            <p>
              Technologies such as: cookies or similar technologies are used by
              LocalCall and our marketing partners, affiliates, or analytics or
              third-party administrators. These technologies are used in
              analyzing trends, administering the site, tracking users’
              movements around the site and to gather demographic information
              about the users. We may receive reports based on the use of these
              technologies on an individual as well as aggregated basis.
            </p>
            <p>
              When using the LocalCall Platform, Clients may enter third-party
              personal information on the platform, such as entering another
              user’s email. This information is only used to provide the Client
              access to the system and is not used for any other purpose.
            </p>
            <p>
              <strong>How does LocalCall use its Clients’ information?</strong>
            </p>
            <p>
              Much of the information collected from Clients is required to be
              collected for administrative needs, such as accounting and
              performance reporting. Periodically, LocalCall may use Clients’
              information to communicate via email newsletters or other avenues
              to offer strategic marketing information and other communications
              to assist in business-related activities. Clients can opt-out from
              future communications by clicking on the unsubscribe links in such
              emails.
            </p>
            <p>
              Please note that under the CAN-SPAM Act, an advertiser is required
              to provide an opt-out mechanism for consumers to unsubscribe from
              future emails from the advertiser. LocalCall is committed to
              proper Internet practices and full compliance with the CAN-SPAM
              Act of 2003 (15 U.S.C. § 7701). It is LocalCall’s policy to
              prohibit the sending of unsolicited or “Spam” e-mail by LocalCall
              or any of its marketing partners.
            </p>
            <p>
              Except as otherwise provided, LocalCall keeps all personal
              information provided by Clients in strict confidence and treats
              such data with the same level of care and respect that LocalCall
              treats its own proprietary information. LocalCall may, from time
              to time, provide personal information to certain third-party
              administrative vendors for efficient purposes in providing
              administrative or program management services. Examples include an
              email service provider, marketing solutions provider and a blog
              provider. Any third-party vendor so used has agreed to protect the
              confidentiality of information provided by LocalCall.
            </p>
            <p>
              LocalCall may also share information in connection with the sale,
              merger, consolidation, change in control, transfer of substantial
              asset, reorganization or liquidation of LocalCall. In this case,
              Clients will be notified via a prominent notice on our website of
              any change in ownership or uses of Client’s personal information,
              as well as any choices Clients may have regarding their personal
              information.
            </p>
            <p>
              LocalCall may release information when it believes release is
              appropriate to respond to a subpoena, bankruptcy proceedings, or
              similar legal process or otherwise comply with the law, to enforce
              or apply LocalCall’s Terms of Service and other agreements or to
              protect the rights, property, or safety of LocalCall or others.
              This includes exchanging information with other companies and
              organizations for fraud protection.
            </p>
            <p>
              <strong>Can a client opt out of providing information?</strong>
            </p>
            <p>
              Collecting information from Clients is required as a part of doing
              business. LocalCall’s primary intent in collecting information is
              for administrative purposes. However, it may periodically use
              collected information for marketing and communication purposes
              targeted to our Clients. Clients wishing not to receive such
              communications may do so by informing their designated LocalCall
              representative in writing at any time or by clicking on the
              unsubscribe link in the promotional communication.
            </p>
            <p>
              We will also send you service related email announcements on rare
              occasions when it is necessary to do so. For instance, if our
              service is temporarily suspended for maintenance, we might send
              you an email. You do not have an option to opt out of these
              emails, which are not promotional in nature.
            </p>
            <p>
              When a Client logs on to any browser-based LocalCall reporting
              system, a cookie is placed within their computer. The cookie
              allows us to validate the Client’s identity and maintain the
              security and privacy of the Client’s account data. This cookie
              does not contain any personal information. It is required to log
              into LocalCall’s reporting systems and there is no method to
              opt-out of this cookie.
            </p>
            <p>
              If you are a Client and would like the personal information
              LocalCall holds about you corrected, updated or deleted, please
              contact legal@LocalCall.io or contact your designated LocalCall
              representative. LocalCall will respond to your request to access
              information within a reasonable timeframe.
            </p>
            <p>
              <strong>
                Information Related to Data Collected through the Service
              </strong>
            </p>
            <p>
              <strong>
                Information Related to Data Collected for our Clients:
              </strong>
            </p>
            <p>
              LocalCall collects personal information only on behalf of its
              Clients to provide them a service. LocalCall may transfer personal
              information to companies that help us provide our service.
              Transfers to subsequent third parties are covered by the
              provisions in this Policy regarding notice and choice and the
              service agreements with our Clients. If you are a customer of one
              of LocalCall’s Clients and would no longer like to be contacted by
              a Client that uses the LocalCall Services, please contact that
              Client directly as that Client controls such data.
            </p>
            <p>
              <strong>
                Access and Retention of Data Controlled by our Clients:
              </strong>
            </p>
            <p>
              LocalCall acknowledges that you have the right to access your
              personal information.&nbsp; LocalCall has no direct relationship
              with the individuals whose personal information it processes on
              behalf of Client. An individual who seeks access to or otherwise
              seeks to correct, amend or delete inaccurate personal information
              should direct such inquiry to LocalCall’s Client. If you do
              contact LocalCall regarding such a request, LocalCall will respond
              within a reasonable timeframe.
            </p>
            <p>
              LocalCall retains all information we process on behalf of our
              Clients for as long as a Client’s account is active or as needed
              to provide Services to the Client. If a Client wishes to cancel an
              account or request that LocalCall no longer use information to
              provide the Services, contact&nbsp;<a href="mailto:legal@LocalCall.io">
                legal@LocalCall.io
              </a>. LocalCall will retain and use a Client’s information as
              necessary to comply with any legal obligations, resolve disputes
              and enforce any agreements.
            </p>
            <p>
              <strong>GENERAL:</strong>
            </p>
            <p>
              <strong>Public Forums:</strong>
              <br />
              LocalCall’s website offers publicly accessible blogs or community
              forums. Any user of the LocalCall website should be aware that any
              information provided in these areas may be read, collected and
              used by others who access these sites. Our blog is also managed by
              a third-party application that may require you to register to post
              a comment.&nbsp; We moderate information posted to the blog. To
              request removal of any personal information from the LocalCall
              blog or other community forum, contact us&nbsp;<a href="mailto:legal@LocalCall.io">
                legal@LocalCall.io
              </a>. In some cases, we may not be able to remove your personal
              information, in which case we will let you know if we are unable
              to do so and why.
            </p>
            <p>
              <strong>Testimonials:</strong>
            </p>
            <p>
              LocalCall will only post Client testimonials with the Client’s
              consent to do so. If you wish to update or delete your
              testimonial, you can contact us at&nbsp;<a href="mailto:legal@LocalCall.io">
                legal@LocalCall.io
              </a>.
            </p>
            <p>
              <strong>Frame:</strong>
            </p>
            <p>
              Some of our pages utilize framing techniques to serve content
              to/from our partners while preserving the look and feel of our
              website. Please be aware that you are providing your personal
              information to these third parties and not to&nbsp;<a href="https://www.LocalCall.io/">www.LocalCall.io</a>.
            </p>
            <p>
              <strong>Links to other websites:</strong>
            </p>
            <p>
              The LocalCall website and platform may include links to other
              websites whose privacy practices may differ from those of
              LocalCall. If a user submits personal information to any of those
              sites, that user’s information is governed by that third party’s
              privacy policies. We encourage users to carefully read the privacy
              policy of any website it visits.
            </p>
            <p>
              <strong>Social Media Widgets:</strong>
            </p>
            <p>
              LocalCall’s corporate website (<a href="https://www.LocalCall.io/">
                www.LocalCall.io
              </a>) includes Social Media Features and Widgets, such as the
              Facebook “Like” button and the “Share This” button, or other
              interactive mini-programs that run on the site. These Features may
              collect a user’s IP address and the page a user visits on the site
              and may also set a cookie to enable the Feature to function
              properly. Social Media Features and Widgets are either hosted by a
              third party or hosted directly on the LocalCall website. A user’s
              interactions with these Features are governed by the privacy
              policy of the company providing them.
            </p>
            <p>
              <strong>
                How does LocalCall protect the security of personal information?
              </strong>
            </p>
            <p>
              LocalCall takes appropriate security measures to protect against
              unauthorized access to or unauthorized alteration, disclosure or
              destruction of data. When a user enters sensitive information
              (such as login information), LocalCall encrypts the transmission
              of that information using secure socket layer technology (SSL).
              Any data stored on LocalCall’s servers is treated as proprietary
              and confidential and is not available to the public. LocalCall
              restricts access to personal information to those employees or
              third parties who have a need to know such information for the
              purpose of effectively delivering LocalCall products and Services
              by means of user login and password requirements. LocalCall
              routinely evaluates its data security practices to identify
              security threats or opportunities for improvement.
            </p>
            <p>
              No transmission of data over the Internet or the phones is
              guaranteed to be completely secure. It may be possible for third
              parties not under the control of LocalCall to intercept or access
              transmissions or private communications unlawfully. While
              LocalCall strives to protect users’ personal information, it
              cannot ensure or warrant the security of any information a user
              transmits. If you have any questions about the security of your
              personal information, you can contact us at&nbsp;<a href="mailto:legal@LocalCall.io">legal@LocalCall.io</a>.
            </p>
            <p>
              <u>Clients and Consumers in the European Union</u>
            </p>
            <p>
              Location of Systems<br />
              LocalCall’s systems are based in the United States, so personal
              data entered in the LocalCall websites or platform, or collected
              through the use of the services (i) will not be processed in the
              European Economic Area (EEA) and (ii) will be processed by
              LocalCall and its service providers in the United States.&nbsp; By
              using the services, data subjects (a) agree to and accept the
              terms stated in this Privacy Policy and (b) expressly consent to
              the processing of personal data on equipment and by service
              providers outside the European Economic Area.&nbsp; LocalCall
              offers a Data Processing Addendum to Clients upon request to
              legal@LocalCall.io.
            </p>
            <p>
              <strong>Controller and Processor</strong>
            </p>
            <p>
              LocalCall is the Controller of data collected from Clients and
              Consumers as described in this policy, as part of normal business
              practices, and in order to provide, monitor and maintain services
              through the LocalCall Platform.&nbsp; LocalCall’s Clients are the
              Controllers of Consumer and other data that is collected through
              the use of the LocalCall platform.&nbsp; LocalCall is the
              Processor on behalf of the Clients of that data.&nbsp; LocalCall
              will process the data according to Client instructions, which are
              given in the process of contracting for the services.
            </p>
            <p>
              <strong>Basis of Processing</strong>
            </p>
            <p>
              Where LocalCall is the Controller of data collected, it processes
              that data on the basis of its legitimate business interest to
              provide the service, and on the basis of fulfilling contractual
              obligations with Clients.&nbsp; In some instances, LocalCall may
              seek consent to process data, for example, if the data will be
              used for marketing communications.&nbsp; If LocalCall has received
              consent to process, then that is the basis for processing, and
              that consent may be withdrawn at any time.&nbsp; Where LocalCall
              is processing data as a Processor, it follows the instructions of
              the Controller.
            </p>
            <p>
              <strong>Data Subject Rights</strong>
            </p>
            <p>
              EU data subjects have additional rights under the General Data
              Protection Regulation:
            </p>
            <ul>
              <li>Right of access to personal data</li>
              <li>
                Right to rectification of personal data held where it is
                incorrect or incomplete
              </li>
              <li>
                Right of erasure of personal data, under certain circumstances
              </li>
              <li>Right to restrict/suspend processing of personal data</li>
              <li>Right to complain to a supervisory authority</li>
              <li>
                Right to object to processing, including for direct marketing
                purposes
              </li>
              <li>
                Right to receive personal data in a standard electronic format
                (data portability)
              </li>
            </ul>
            <p>
              Where LocalCall is the Controller of the data, the data subject
              may withdraw consent or exercise any of these rights by contacting
              LocalCall at legal@LocalCall.io.&nbsp;&nbsp;Where LocalCall is the
              Processor of the data, the data subject should contact the
              appropriate data Controller to exercise these rights.
            </p>
            <p>
              <strong>
                Right to Review, Delete and Control Use of Information
              </strong>
            </p>
            <p>
              Data subjects have the right to request access to, and correction
              or deletion of, personal information, and to request that
              processing of the data be stopped.&nbsp; Data subjects may always
              opt-out of direct marketing by unsubscribing from communications,
              or by contacting LocalCall.&nbsp; Clients may review personal
              information through their account.&nbsp; Clients and Consumers may
              also contact LocalCall at legal@LocalCall.io.&nbsp; Where
              LocalCall is the Controller of the information, it will respond to
              the request in a timely manner.&nbsp; Where LocalCall is the
              Processor of the data, the data subject should contact the
              Controller directly to exercise these rights.
            </p>
            <p>
              <strong>Right to Data Portability</strong>
            </p>
            <p>
              Data subjects may obtain a copy of personal information,
              structured in a commonly used and machine-readable format.&nbsp;
              If requested and when technically feasible, and where LocalCall is
              the Controller of the information, LocalCall will send that
              information via electronic means to a designated third-party.
              Where LocalCall is the Processor of the data, data subjects should
              contact the appropriate Controller to exercise this right.
            </p>
            <p>
              <strong>Retention</strong>
            </p>
            <p>
              LocalCall is subject to various retention requirements and retains
              personal data as described in this policy, for as long as
              necessary for a specified purpose or legal requirement, and in
              accordance with any applicable regulations.
            </p>
            <p>
              <strong>How are changes made to the Privacy Policy?</strong>
            </p>
            <p>
              LocalCall may find it necessary or be legally obligated to update
              this Privacy Policy from time to time. When it does update this
              policy, LocalCall will post those changes on this page and update
              the effective date so that everyone is aware of the information
              that is collected, how LocalCall uses it and under what
              circumstances LocalCall discloses it. Except as otherwise required
              by law, modifications to this Privacy Policy will not affect the
              privacy of data collected by LocalCall prior to the effective date
              of the policy change. If LocalCall makes any material changes,
              LocalCall will notify you or our Client by email (sent to the
              email address specified in your account) or by means of a notice
              within the Services platform or the corporate website prior to the
              changes becoming effective. LocalCall encourages all users to
              periodically review this page for the latest information on
              LocalCall’s privacy practices.
            </p>
            <p>
              <strong>
                What are LocalCall’s policies regarding children and SPAM?
              </strong>
            </p>
            <p>
              The LocalCall Services are not developed for or directed at
              children. Children (persons under the age of 18) are not eligible
              to do business with LocalCall and it does not collect personal
              information from any person it actually knows is under the age of
              18. If you believe your child has provided LocalCall with any
              personal information, please contact&nbsp;<a href="mailto:legal@LocalCall.io">
                legal@LocalCall.io
              </a>&nbsp;to have that user removed.
            </p>
            <p>
              <strong>Contact LocalCall:</strong>
            </p>
            <p>
              For more information or if you have questions or concerns
              regarding the LocalCall Privacy Policy, please contact
              legal@LocalCall.io or send correspondence to the following
              address: LocalCall, Inc. Attn: Privacy, 419 State Street, Santa
              Barbara, CA 93101.
            </p>
            <p>Policy Updated: March 28, 2019</p>
            <p>
              Email: legal@LocalCall.io<br />
            </p>
            LocalCall, Inc 108 Street Austin, TX 78641
            <p>Last Updated: December 6, 2019</p>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
