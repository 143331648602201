import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { GetStartedModal } from "components";
import { useToggle } from "helpers";

export const GetStarted = () => {
  const [toggled, toggle] = useToggle();

  return (
    <section className="section-default min-vh-100 d-flex align-items-center">
      <Container>
        <Row>
          <Col md="6"></Col>
          <Col md="6">
            <div className="box-holder">
              <form>
                <ol className="tp-form-fields">
                  <li className="tp-form-field__item">
                    <h2 className="heading medium mb-2">
                      Meet More Customer to <span>Lander</span>.
                    </h2>
                    <label className="tp-label">
                      What Service Do you provide?
                    </label>
                    <div className="tp-input-icon tp-input-icon--left">
                      <input
                        type="text"
                        className="tp-text-input"
                        placeholder="Meditation Instruction"
                      />
                      <svg
                        className="tp-input-icon__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 28"
                        fill="#1280ab"
                        data-thumbprint-id="contentmodifier-map-pin--medium"
                      >
                        <path d="M19.0245,17.611 C20.2585,16.071 20.9995,14.122 20.9995,12 C20.9995,7.037 16.9635,3 11.9995,3 C7.0365,3 2.9995,7.037 2.9995,12 C2.9995,16.963 7.0365,21 11.9995,21 C14.1215,21 16.0705,20.258 17.6105,19.025 L23.2935,24.707 C23.4875,24.902 23.7435,25 23.9995,25 C24.2565,25 24.5125,24.902 24.7065,24.707 C25.0985,24.316 25.0985,23.684 24.7065,23.293 L19.0245,17.611 Z M4.9995,12 C4.9995,8.141 8.1415,5 11.9995,5 C15.8585,5 18.9995,8.141 18.9995,12 C18.9995,15.859 15.8585,19 11.9995,19 C8.1415,19 4.9995,15.859 4.9995,12 Z" />
                      </svg>
                    </div>
                  </li>

                  <li className="tp-form-field__item">
                    <div className="tp-input-icon tp-input-icon--left">
                      <input
                        type="text"
                        className="tp-text-input"
                        placeholder="Enter an address"
                      />
                      <svg
                        className="tp-input-icon__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 28"
                        fill="#1280ab"
                        data-thumbprint-id="contentmodifier-map-pin--medium"
                      >
                        <path d="M5,11.25 C5,16.4940121 12.389,24.063 13.231,25.075 L14,26 L14.77,25.075 C15.612,24.063 23,16.5119319 23,11.25 C23,5.98806805 19.909,2 14,2 C8.092,2 5,6.00598789 5,11.25 Z M7,11.25 C7,7.758 9.191,4 14,4 C18.81,4 21,7.758 21,11.25 C21,14.4672893 16.49,19.71 14,22.848 C11.511,19.711 7,14.6244496 7,11.25 Z M14,7 C11.794,7 10,8.794 10,11 C10,13.206 11.794,15 14,15 C16.206,15 18,13.206 18,11 C18,8.794 16.206,7 14,7 M14,13 C12.897,13 12,12.103 12,11 C12,9.897 12.897,9 14,9 C15.103,9 16,9.897 16,11 C16,12.103 15.103,13 14,13" />
                      </svg>
                    </div>
                  </li>

                  <li className="tp-form-field__item">
                    <button
                      type="button"
                      className="tp-button tp-button--full"
                      onClick={toggle}
                    >
                      Get Started
                    </button>
                    <GetStartedModal toggle={toggle} toggled={toggled} />
                    <h2 className="heading mt-3">30,000+</h2>
                    <p className="small-text mt-2">
                      In addition to setting attributes on objects
                    </p>
                  </li>
                </ol>
              </form>
            </div>
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col md="12" className="show-mobile">
            <div className="info-block">
              <h4 className="mb-2">Managing a Large team?</h4>
              <a
                className="tp-button tp-button--tertiary tp-button--full"
                href="#/"
              >
                Contact Sales
              </a>
            </div>
          </Col>
          <Col xs="4" md="3">
            <div className="info-block">
              <p>
                <i className="la la-briefcase icons-s"></i>
              </p>
              <h4>See Jobs</h4>
            </div>
          </Col>
          <Col xs="4" md="3">
            <div className="info-block">
              <p>
                <i className="la la-lightbulb-o icons-s"></i>
              </p>
              <h4>See How It Works</h4>
            </div>
          </Col>

          <Col xs="4" md="3">
            <div className="info-block">
              <p>
                <i className="la la-question icons-s"></i>
              </p>
              <h4>Pricing</h4>
            </div>
          </Col>
          <Col md="3" className="hide-mobile">
            <div className="info-block">
              <h4 className="mb-2">Managing a Large team?</h4>
              <a className="tp-button tp-button--tertiary" href="#/">
                Contact Sales
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
