import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export const Signup = () => (
  <React.Fragment>
    <div className="section-default">
      <Container>
        <Row>
          <Col md="12">
            <h1 className="fancyheading text-center">Create Account</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="box-holder">
              <form>
                <ol className="tp-form-fields">
                  <li className="tp-form-field__item">
                    <label className="tp-label">First Name</label>
                    <input type="text" className="tp-text-input" />
                  </li>

                  <li className="tp-form-field__item">
                    <label className="tp-label">Last Name</label>
                    <input type="text" className="tp-text-input" />
                  </li>

                  <li className="tp-form-field__item">
                    <label className="tp-label">Email </label>
                    <input type="email" className="tp-text-input" />
                  </li>

                  <li className="tp-form-field__item">
                    <label className="tp-label">Password</label>
                    <input type="password" className="tp-text-input" />
                  </li>

                  <li className="tp-form-field__item text-center">
                    <p className="text-center small-text">
                      By Clicking Create Account, You agree to the{" "}
                      <a href="#/" className="link-default">
                        Terms of use
                      </a>{" "}
                      and{" "}
                      <a href="#/" className="link-default">
                        Privacy Policy{" "}
                      </a>
                    </p>
                  </li>

                  <li>
                    <button className="tp-button tp-button--full">
                      Create Account
                    </button>
                  </li>

                  <div className="text-divider">OR</div>

                  <p className="text-center small-text">
                    By Clickng Sign Up with Faceook or Sign Up with Google, you
                    agree to the{" "}
                    <a href="#/" className="link-default">
                      Terms of use
                    </a>{" "}
                    and
                    <a href="#/" className="link-default">
                      Privacy Policy{" "}
                    </a>
                  </p>
                </ol>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
);
