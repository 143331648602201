import React from "react";
import { Row, Col, Container } from "react-bootstrap";
export const Tos = () => (
  <section className="section-default min-vh-100">
    <Container>
      <Row className="justify-content-center ">
        <Col md="12">
          <div className="short-des">
            <p>
              We use this info to help ensure trust and safety for LocalCall
              Clients
            </p>
          </div>
          <form>
            <div className="box-holder">
              <b>TERMS OF SERVICE</b>
              <br />
              <br />
              <p>
                This agreement is between LocalCall, Inc (“we,” “us” or
                “LocalCall”) and you (“you”), as an authorized user of any
                service offered by LocalCall (individually and collectively, the
                “Services”) and governs the terms and conditions of your use of
                the Services. The Services are provided to&nbsp;you under these
                Terms of Service, our privacy policy (<a href="/privacy">www.LocalCall.io/privacy</a>),
                and any operating rules and/or&nbsp;policies that we may publish
                from time to time (collectively, the “Agreement”), and you agree
                you are a direct party to those agreements, rules
                and&nbsp;policies. This Agreement and such policies constitute
                the entire agreement between you and us and supersede all prior
                agreements between the parties&nbsp;regarding the subject matter
                contained herein.
              </p>
              <p>
                <br />
                <b>1. ACCEPTANCE OF TERMS</b>
              </p>
              <ol class="tos_a">
                <li>
                  BY USING ANY OF THE SERVICES, YOU CONFIRM YOUR ACCEPTANCE OF,
                  AND AGREE TO BE BOUND BY, THIS AGREEMENT.
                </li>
                <li>
                  We may amend the Agreement at any time, and such amended
                  Agreement will automatically be effective seven (7) days after
                  we have posted the amended&nbsp;Agreement on our website. Your
                  continued use of the Services will constitute acceptance of
                  the amended Agreement, and no other Agreement version
                  will&nbsp;be effective or enforceable against us.
                </li>
                <li>
                  The rights granted to you under this Agreement are expressly
                  non-exclusive. You may not sell, assign, sublicense, or
                  otherwise transfer or agree to&nbsp;transfer all or any
                  portion of those rights without our prior written consent.
                </li>
                <li>
                  We may reject this Agreement for any reason or no reason,
                  prior or subsequent to acceptance of the Agreement. Activation
                  of our Services shall&nbsp;indicate our acceptance, but does
                  not obligate us to provide or continue to provide access to
                  any or all Services, such access will instead be
                  provided&nbsp;in our discretion.
                </li>
              </ol>
              <p>
                <br />
                <b>2. REQUIREMENTS FOR LocalCall SERVICES</b>
              </p>
              <ol class="tos_a">
                <li>
                  You must either: (i) enter into a separate agreement with us
                  describing the specific terms and conditions of the Services
                  to be provided to you, or&nbsp;(ii) complete the registration
                  process made available to you by us or by our partner(s), and
                  such registration must be approved by us. Such
                  separate&nbsp;agreement or registration process may be in one
                  or more different formats and may be made available to you
                  either online or offline, subject to change&nbsp;without prior
                  notice.
                </li>
                <li>
                  Our Services are offered only to parties that can legally
                  enter into and sign binding contracts. By accepting the terms
                  of this Agreement, you&nbsp;represent and warrant to us that
                  you are age eighteen (18) or older and the authorized
                  signatory.
                </li>
                <li>
                  In consideration of the Services, you agree to: (i) provide
                  current, complete, and accurate information about your
                  identity, location, contact&nbsp;information, internet access
                  and telecommunications Services as are required by us to
                  provide our Services; (ii) maintain and update the information
                  as&nbsp;required to keep such information current, complete,
                  and accurate; (iii) use our Services only in accordance with
                  applicable law; (iv) comply with&nbsp;United States law
                  regarding the transmission of technical data exported from the
                  United States through our Services; (v) not use our Services
                  for&nbsp;illegal purposes; (vi) not interfere with or disrupt
                  networks connected to our Services; (vii) comply with all
                  regulations, policies and procedures of&nbsp;networks
                  connected to our Services; and (viii) with respect to our
                  Services, transfer calls only to legitimate recipients and/or
                  destinations.
                </li>
              </ol>
              <p>
                <br />
                <b>3. LocalCall SERVICES</b>
              </p>
              <ol class="tos_a">
                <li>
                  When using the Services, you shall be subject to any posted
                  guidelines, rules, or licenses applicable to such Services,
                  this Agreement, or any other&nbsp;separate agreement between
                  us. Such guidelines, rules, or licenses may contain terms and
                  conditions in addition to those described in this Agreement.
                </li>
                <li>
                  We may refuse the Services to anyone at any time, in our sole
                  discretion. We reserve the right to discontinue, temporarily
                  or permanently, any or&nbsp;all of the Services to you,
                  including by shutting down phone numbers, at any time,
                  provided we will make reasonable efforts to notify you
                  within&nbsp;twenty-four (24) hours of doing so. However, we
                  reserve the right to discontinue any or all of your use of the
                  Services immediately and with no prior&nbsp;or subsequent
                  notice required if we suspect you may be in breach of your
                  obligations under this Agreement. We will refund you any
                  unused amounts we&nbsp;have charged you for prepaid Services
                  that we discontinued; as such amounts are determined by us in
                  our discretion. We shall not be liable to you or&nbsp;any
                  third-party for any termination of your access to the Services
                  except as specifically set forth in this Agreement.
                </li>
                <li>
                  You agree that we will not be liable for damages (including
                  consequential or special damages) arising out of any change of
                  assigned telephone&nbsp;number(s), and you hereby waive any
                  claims with respect to any such change, whether based on
                  contractual, tort or other grounds, even if we have
                  been&nbsp;advised of the possibility of damages. We reserve
                  the right to withdraw telephone numbers from Services at our
                  discretion when we determine that a&nbsp;telephone number is
                  receiving insufficient use. You acknowledge that our policies
                  and practices with regard to recapturing and repurposing
                  telephone&nbsp;numbers may change from time-to-time at our
                  discretion.
                </li>
                <li>
                  We will only use your information in accordance with our
                  Privacy Policy, which is a part of this Agreement.
                </li>
                <li>
                  We shall take all steps we deem reasonably necessary to shield
                  you from any personally identifiable information of a party
                  calling a number utilized&nbsp;in the Services, including
                  without limitation personal information of such caller (as
                  described in our Privacy Policy), caller ID information, and
                  any&nbsp;recordings of any calls utilized in connection with
                  the Services.
                </li>
                <li>
                  The Services are designed to be used in conjunction with local
                  exchange telephone, wireless telephone, or equivalent
                  telecommunications services&nbsp;that the Member procures from
                  a third-party provider rather than as a substitute or
                  replacement for such third-party telecommunications
                  services.&nbsp;EMERGENCY SERVICES CANNOT BE ACCESSED BY
                  ORIGINATING CALLS TO 911 USING THE SERVICES. You understand
                  and acknowledge that emergency services cannot
                  be&nbsp;accessed by originating calls to 911 using the
                  Services. You understand and acknowledge that you must have
                  and use a different means of contacting 911&nbsp;emergency
                  services.
                </li>
                <li>
                  Neither us nor any of our affiliates, subsidiaries, parent
                  companies, agents, network services providers, contractors,
                  suppliers, partners,&nbsp;officers, directors or employees are
                  or will be liable to any extent or in any manner whatsoever
                  for the inability of persons to access emergency&nbsp;services
                  by originating a call to 911 using the Services.
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT,
                  YOU AGREE TO DEFEND, INDEMNIFY&nbsp;AND HOLD HARMLESS US, OUR
                  AFFILIATES, SUBSIDIARIES, PARENT COMPANIES, AGENTS, NETWORK
                  SERVICE PROVIDERS, CONTRACTORS, SUPPLIERS, PARTNERS,
                  OFFICERS,&nbsp;DIRECTORS OR EMPLOYEES IN CONNECTION WITH THE
                  SERVICES FROM AND AGAINST ANY AND ALL LOSSES, CLAIMS,
                  LIABILITIES, EXPENSES (INCLUDING BUT NOT LIMITED
                  TO&nbsp;ATTORNEYS’ FEES), PENALTIES, FINES, DEATH, INJURY TO
                  PERSONS OR DAMAGES TO TANGIBLE PROPERTY ARISING BY VIRTUE OF
                  OR RELATING TO THE ABSENCE OF 911&nbsp;SERVICE AND/OR THE
                  INABILITY OF MEMBER OR ANY THIRD PERSON OR PARTY TO BE ABLE TO
                  DIAL 911 USING THE SERVICES. This Section 3 shall survive
                  termination&nbsp;of this Agreement.
                </li>
              </ol>
              <p>
                <br />
                <b>4. TERM OF AGREEMENT</b>
              </p>
              <ol class="tos_a">
                <li>
                  The Services are offered on an open-ended basis for a term
                  that begins on the date your Services are activated and you
                  accept the Agreement (the&nbsp;“activation date”). This
                  Agreement shall be deemed to be perpetually in effect without
                  further action by you or by us unless either a) we terminate
                  the&nbsp;provision of Services to you or b) you give us
                  written notice of termination at least thirty (30) days prior
                  to the intended date of termination for&nbsp;which notice is
                  given.
                </li>
                <li>
                  If you terminate Services prior to the end of a campaign or
                  otherwise defined term, you may be responsible for charges
                  incurred by you subsequent to&nbsp;such termination, including
                  without limitation unbilled charges, all of which immediately
                  become due and payable.
                </li>
                <li>
                  Expiration of the term or termination of Services does not
                  excuse you from paying all unpaid, accrued charges.
                </li>
              </ol>
              <p>
                <br />
                <b>5. FEES</b>
              </p>
              <p>
                We or our partner(s) will charge you fees in accordance with the
                terms of either: (i) a separate agreement, or (ii) the
                registration process you&nbsp;complete. Unless otherwise stated,
                all fees are quoted in U.S. dollars. You are responsible for
                paying all fees associated with using the Services and&nbsp;all
                applicable taxes.
              </p>
              <p>
                <br />
                <b>6. INTELLECTUAL PROPERTY; LIMITED LICENSE TO USERS</b>
              </p>
              <p>
                The Services are protected by copyright, trademark, patent,
                and/or other intellectual property laws, and any unauthorized
                use of the Services may&nbsp;violate such laws and this
                Agreement. Except as expressly provided herein, we do not grant
                any express or implied rights to use the Services. You
                agree&nbsp;not to copy, republish, frame, download, transmit,
                modify, rent, lease, loan, sell, assign, distribute, license,
                sublicense, reverse engineer, or&nbsp;create derivative works
                based on the Services, except as expressly authorized herein.
              </p>
              <p>
                <br />
                <b>7. MODIFICATIONS OF LocalCall SERVICES</b>
              </p>
              <ol class="tos_a">
                <li>
                  We reserve the right to terminate or modify individual
                  features of our Services from time to time with or without
                  notice. In the event we opt to&nbsp;send written notice to you
                  detailing such termination or modification, an email will be
                  sent to the email address you most recently provided to us.
                  Any&nbsp;such termination or modification will take effect
                  upon the date of such written notice or such later date as
                  specified therein. We are not responsible&nbsp;for confirming
                  the receipt of any such notification.
                </li>
                <li>
                  We may, in our sole discretion, determine the extent, if any,
                  to which any such modifications may impact the charges for the
                  Services, but we shall&nbsp;not have any obligation to adjust
                  the charges by reason of any such modifications.
                </li>
                <li>
                  We shall not be liable to you or any third party should we
                  exercise our right to modify or terminate our Services or any
                  feature of such Services.
                </li>
              </ol>
              <p>
                <br />
                <b>8. YOUR CONDUCT</b>
              </p>
              <ol class="tos_a">
                <li>
                  Your use of the Services is subject to all applicable local,
                  state, national and international laws and regulations.
                </li>
                <li>
                  You shall be fully liable for all use of your account,
                  including any unauthorized use of such account by any third
                  party. We bear no responsibility&nbsp;for communications
                  received by you from third persons while using the Services or
                  any use of any person’s telecommunications services, including
                  but&nbsp;not limited to, your telecommunications services in
                  connection with the use of the Services.
                </li>
                <li>
                  You shall not interfere with any other client’s use and
                  enjoyment of the Services.
                </li>
                <li>
                  You shall not use the Services to commit fraud or to promote
                  any product or service that is illegal, pornographic or
                  involves any adult services&nbsp;(whether or not legal).
                </li>
                <li>
                  You shall not use the Services to post on any website (whether
                  hosted by you or that we host on your behalf) any content that
                  is illegal, any&nbsp;content that violates the trademarks,
                  copyrights or other intellectual property rights of a third
                  party, any content that contains any adult or&nbsp;sexually
                  explicit material, or any content that is false, defamatory or
                  otherwise misleading in any way.
                </li>
                <li>
                  You shall not use the Services to offer products or services
                  to, or to collect information from, any child under the age of
                  13.
                </li>
                <li>
                  When you post content (“User Content”) on our website or any
                  webpage (whether hosted by you or that we host on your
                  behalf), you represent and&nbsp;warrant: (1) that you are the
                  owner of all User Content or that you have a license
                  permitting you to post such content; (2) that the User Content
                  is&nbsp;not illegal, false, defamatory or misleading in any
                  way; and (3) that the User Content is not for the purpose of
                  offering products or services to&nbsp;children under the age
                  of 13.
                </li>
                <li>
                  We may, in our discretion, terminate the Services we provide
                  you without notice if you fail to observe and comply with the
                  terms of this Agreement.&nbsp;Failure on our part to actively
                  terminate your account following any violation by you of this
                  Agreement shall not constitute a waiver of our right
                  to&nbsp;terminate and shall not prevent us from terminating
                  you account, consistent with this Agreement, at any point for
                  past and/or current violations of&nbsp;Agreement.
                </li>
              </ol>
              <p>
                <br />
                <b>9. DISCLAIMER OF WARRANTIES</b>
              </p>
              <ol class="tos_a">
                <li>
                  We expressly disclaim all warranties of any kind, whether
                  expressed or implied, including, but not limited to the
                  implied warranties of&nbsp;merchantability, fitness for a
                  particular purpose and non-infringement.
                </li>
                <li>
                  We make no warranty that the Services will meet your
                  requirements, or that the Services will be uninterrupted,
                  timely, secure, or error free; nor do&nbsp;we make any
                  warranty as to the results that may be obtained from the use
                  of our Services or as to the accuracy or reliability of any
                  information&nbsp;obtained through our Services, or that
                  defects in our Services will be corrected.
                </li>
                <li>
                  We will make commercially reasonable efforts to resolve any
                  technical problems you may experience while using the
                  Services.
                </li>
              </ol>
              <p>
                <br />
                <b>10. LIMITATION OF LIABILITY</b>
              </p>
              <p>
                WE SHALL NOT HAVE ANY LIABILITY TO YOU FOR LOST PROFITS OR OTHER
                CONSEQUENTIAL, SPECIAL, INDIRECT OR INCIDENTAL DAMAGES, BASED
                UPON A CLAIM OF ANY TYPE&nbsp;OR NATURE (INCLUDING, BUT NOT
                LIMITED TO, CONTRACT, TORT, INCLUDING NEGLIGENCE, WARRANTY OR
                STRICT LIABILITY), EVEN IF ADVISED OF THE POSSIBILITY
                OF&nbsp;SUCH DAMAGES. UNDER NO CIRCUMSTANCES SHALL OUR
                LIABILITY, IF ANY, EXCEED THE AMOUNTS CHARGED TO YOU AND PAID BY
                YOU FOR THE SERVICE IN QUESTION. EXCEPT&nbsp;AS EXPRESSLY SET
                FORTH HEREIN, WE MAKE NO OTHER WARRANTIES TO YOU AND DISCLAIM
                ALL WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                PURPOSE.
              </p>
              <p>
                <br />
                <b>11. SERVICE OUTAGES</b>
              </p>
              <ol class="tos_a">
                <li>
                  The Services are reliant upon independent services provided by
                  third-parties that are beyond our control. The loss or
                  interruption of any one of&nbsp;those independent services may
                  prevent the Services from operating. We will use commercially
                  reasonable efforts to manage all communications
                  initiated&nbsp;by you and/or intended for you during any loss
                  or interruption of such independent services.
                </li>
                <li>
                  Such independent services interruptions may include, but are
                  not limited to:
                  <ol>
                    <li>Power outages;</li>
                    <li>Internet Service Provider outages;</li>
                    <li>Telecommunications services provider outages;</li>
                    <li>Outages due to suspension of your account; and</li>
                    <li>
                      Outages due to your failure to maintain the services used
                      in conjunction with the Services.
                    </li>
                  </ol>
                </li>
                <li>
                  We shall not be responsible for any delay or failure in
                  performance that results from causes beyond its reasonable
                  control (“Force Majeure Events”),&nbsp;whether or not
                  foreseeable by such party. Such Force Majeure Events include,
                  but are not limited to, adverse weather conditions, flood,
                  fire,&nbsp;explosion, earthquake, volcanic action, power
                  failure, embargo, boycott, war, revolution, civil commotion,
                  act of public enemies, labor unrest&nbsp;(including, but not
                  limited to, strikes, work stoppages, slowdowns, picketing or
                  boycotts), inability to obtain equipment, parts, licenses,
                  software or&nbsp;repairs thereof, acts or omissions of the
                  other party, and acts of God.
                </li>
                <li>
                  Our ability to perform our obligations under this Agreement is
                  dependent upon the availability of equipment and services from
                  third parties&nbsp;(collectively, the “Vendors”) with whom we
                  have contracted for the provision of telecommunications,
                  internet services and equipment, and other&nbsp;third-party
                  services used by us when providing the Services. You agree
                  that we shall not be in breach of our obligations to you
                  hereunder if we are&nbsp;prevented from performing such
                  obligations by reason of the breach or other failure by any
                  such Vendor to perform its obligations to us in
                  accordance&nbsp;with the terms of such Vendor’s agreement with
                  us.
                </li>
              </ol>
              <p>
                <br />
                <b>12. INDEMNIFICATION</b>
              </p>
              <p>
                You shall indemnify, defend, and hold us, our parent(s),
                subsidiaries, affiliates, officers, directors, and employees
                free and harmless from any and&nbsp;all claims, costs, damages,
                and expenses (including but not limited to reasonable attorneys’
                fees), which arise from or are related to any act
                or&nbsp;omission by you in connection with the use of the
                Services, including but not limited to, any such claims, costs,
                damages, and expenses arising from or&nbsp;related to your
                violation of any terms of this Agreement, your violation of any
                applicable law, rule, or regulation, or any infringement by you
                (or any&nbsp;other person using the Services in reliance on your
                rights under this Agreement) of any intellectual property or
                other rights of any other person. This&nbsp;Section 12 shall
                survive termination of this Agreement.
              </p>
              <p>
                <br />
                <b>13. TERMINATION</b>
              </p>
              <ol>
                <li>
                  Either you or we may terminate the Services with or without
                  cause at any time (the “Termination”). Without limiting the
                  foregoing, we reserve the&nbsp;right to cancel this Agreement
                  or terminate any Services provided to you if one or more of
                  the telephone numbers assigned to you is not
                  legitimately&nbsp;used in conjunction with the Services during
                  any period of thirty (30) consecutive days. Our failure to
                  exercise this right in relation to any specific&nbsp;period of
                  nonuse shall not preclude us from thereafter invoking its
                  right to cancel the Agreement or terminate the Services.
                </li>
                <li>
                  All notices, demands or consents required or permitted under
                  this Agreement shall be in writing and delivered to us at the
                  address set forth below&nbsp;or at such other address as we
                  may hereafter designate within this Agreement: LocalCall, Inc
                  108 Street Austin, TX 78641
                </li>
                <li>
                  Notice shall be considered delivered and effective on the
                  earlier of actual receipt or when (a) personally delivered;
                  (b) the day following&nbsp;transmission if sent by facsimile
                  when followed by written confirmation by overnight carrier or
                  certified United States mail; or (c) one (1) day
                  after&nbsp;posting when sent by overnight carrier; or (d) five
                  (5) days after posting when sent by certified United States
                  mail.
                </li>
                <li>
                  Notwithstanding the foregoing, an online termination process
                  may be made available on our web site. If no such online
                  termination process is made&nbsp;available, however, then the
                  above requirements for notification shall be in effect for all
                  such notices of your intent to terminate the Services.
                </li>
                <li>
                  Notwithstanding any other provision of this Agreement to the
                  contrary, we shall not be liable to you or any third party, or
                  responsible for any&nbsp;charges incurred for the termination
                  of services you obtain from third-party providers.
                </li>
                <li>
                  Upon Termination, any telephone number(s) assigned to you for
                  use with the Services will become unavailable for use and,
                  after an aging period, be&nbsp;returned to the inventory of
                  telephone numbers and become available for assignment by us.
                  No telephone number will be available for your use
                  or&nbsp;reserved for you after your Services have been
                  terminated.
                </li>
              </ol>
              <p>
                <br />
                <b> 14. TRADEMARK AND PROPRIETARY RIGHTS TO CONTENT </b>
              </p>
              <p>
                Our trademarks, websites, corporate names, trade names, domain
                names, logos, and service marks (“Marks”) are the property of
                LocalCall. You are not&nbsp;permitted to use the Marks without
                the prior written consent of LocalCall. “LocalCall,” “LocalCall”
                and the LocalCall logo are trademarks of&nbsp;LocalCall, Inc.
              </p>
              <p>
                <br />
                <b> 15. NO THIRD PARTY BENEFICIARIES </b>
              </p>
              <p>
                No provision of this Agreement provides any person or entity not
                a party to this Agreement with any remedy, claim, liability,
                reimbursement, or cause&nbsp;of action or creates any other
                third party beneficiary rights.
              </p>
              <p>
                <br />
                <b> 16. ARBITRATION </b>
              </p>
              <p>
                All disputes which arise under this Agreement or in connection
                with the Services to be delivered hereunder and which are not
                resolved within thirty&nbsp;(30) days following the delivery by
                one party to the other of a written notice describing the
                dispute shall be resolved by binding arbitration under&nbsp;the
                rules of the American Arbitration Association before a single
                arbitrator in Santa Barbara, California. The decision of the
                arbitrator shall be&nbsp;final and binding on the parties, and
                judgment thereon shall be entered in a court of competent
                jurisdiction. YOU ACKNOWLEDGE AND AGREE THAT BY&nbsp;CONSENTING
                TO THIS AGREEMENT, YOU ARE CONSENTING TO BINDING ARBITRATION OF
                ALL DISPUTES HEREUNDER, AND THAT IN ARBITRATION, YOU SHALL NOT
                HAVE A RIGHT&nbsp;TO A JURY TRIAL, SHALL HAVE LIMITED DISCOVERY
                RIGHTS, AND SHALL HAVE VERY LIMITED RIGHTS OF APPEAL FROM THE
                DECISION OF THE ARBITRATOR.
              </p>
              <p>
                NOTHING IN THIS SECTION 16: ARBITRATION IS INTENDED OR SHALL BE
                CONSTRUED TO MODIFY OR OTHERWISE BE INCONSISTENT WITH THE
                PROVISIONS OF SECTION 13:&nbsp;TERMINATION, ABOVE, WHICH SETS
                FORTH THE SOLE AND EXCLUSIVE REMEDY OF ANY MEMBER WHO OBJECTS TO
                ANY TERMS OF THIS AGREEMENT (OR ANY MODIFICATION&nbsp;HEREOF) OR
                BECOMES DISSATISFIED WITH ANY OF THE SERVICES.
              </p>
              <p>
                <br />
                <b> 17. MISCELLANEOUS </b>
              </p>
              <p>
                The Agreement shall be governed by and construed in accordance
                with the internal laws of the State of California, excluding its
                conflict of law&nbsp;provisions. Subject to Section 16:
                Arbitration, above, and without limiting the applicability of
                any provisions there under, we and you agree to submit&nbsp;to
                the exclusive jurisdiction of the courts of the State of
                California and further agree that the exclusive venue for any
                cause of action arising under&nbsp;or relating to this Agreement
                or the Services provided hereunder shall be the Superior Court
                in and for Santa Barbara County, California, sitting in&nbsp;the
                City of Santa Barbara, California. We and you agree that any
                cause of action arising out of or related to the Agreement or
                the Services must&nbsp;commence within one (1) year after the
                cause of action arose; otherwise, such cause of action is
                permanently barred.
              </p>
              <p>
                If any provision of the Agreement is determined by an arbitrator
                or court of competent jurisdiction to be contrary to applicable
                law, then such&nbsp;provision shall be construed as nearly as
                possible to conform to applicable law and the other provisions
                of this Agreement shall remain in full force&nbsp;and effect.
              </p>
              <p>
                The Agreement (i) constitutes the entire agreement between the
                parties supersedes all prior agreements between the parties
                regarding the subject matter&nbsp;contained herein, and (ii) may
                be modified or amended only in the manner expressly set forth
                herein.
              </p>
              <p>
                We at any time and from time to time may assign our rights and
                delegate our duties under this Agreement without your prior
                consent. You may not assign&nbsp;this Agreement to any other
                person under any circumstances without our express prior written
                consent.
              </p>
              <p>
                Our failure to exercise or enforce any right or provision of the
                Agreement shall not constitute a waiver of such right or
                provision unless acknowledged&nbsp;and agreed to by us in
                writing.
              </p>
              <p>
                The section titles in the Agreement are solely used for the
                convenience of the parties and have no legal or contractual
                significance.
              </p>
              <p>You may contact us by:</p>
              <p>
                Email: legal@LocalCall.io<br />
              </p>
              LocalCall, Inc 108 Street Austin, TX 78641
              <p>Last Updated: December 6, 2019</p>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  </section>
);
