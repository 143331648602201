import { API } from "helpers";
import { authHeader } from "helpers";

export const leadsService = {
  getLeads
};

function getLeads() {
  const params = {
    url: "/leads",
    method: "get",
    headers: authHeader()
  };

  return API.request(params);
}
