import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { userActions } from "actions";

import "./Navigation.css";

const mapStateToProps = state => ({
  user: state.authentication.user
});

const Navigation = ({ user }) => {
  const dispatch = useDispatch();

  return (
    <Navbar expand="lg" variant="light" className="mybg nav">
      <Container fluid="true">
        <Navbar.Brand>
          <Link to="/"><img src={require('../../assets/images/logo.jpg')} alt="logo" className="logo" /></Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {user && (
              <Nav.Item>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/leads"
                >
                  Leads
                </NavLink>
              </Nav.Item>
            )}

            {user ? (
              <NavDropdown title="Profile" id="basic-nav-dropdown">
                <NavDropdown.Item as="span">
                  <Link to="/settings">Settings</Link>
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => dispatch(userActions.logoutRequest())}
                  as="span"
                  className="logout"
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Item>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/login"
                >
                  Log in
                </NavLink>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const connectedNavigation = connect(
  mapStateToProps,
  null
)(Navigation);
export { connectedNavigation as Navigation };
