import { store } from ".";

export function authHeader() {
  let { authentication: { user } } = store.getState();

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return {};
  }
}
export function authClear() {
  store.clear();
}
