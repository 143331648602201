
import React from "react";
import "./Settings.css";
import { Row, Col, Container, Card, ProgressBar } from "react-bootstrap";
import {
  Carousel,
  Button,
  StarRating,
  Checkbox
} from "@thumbtack/thumbprint-react";

function CarouselDemo() {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  function onSelectedIndexChange(newIndex) {
    setSelectedIndex(Math.round(newIndex));
  }

  return (
    <React.Fragment>
      <div className="silder_container">
        <Carousel
          visibleCount={1}
          spacing="16px"
          selectedIndex={selectedIndex}
          onSelectedIndexChange={onSelectedIndexChange}
        >
          <div className="slider">
            <div className="icon">
              <i className="la la-lightbulb-o"></i>
            </div>
            <div className="sldier_content">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content
              </p>
            </div>
            <div className="sldier_button">
              <Button size="small">Get Reviews</Button>
            </div>
          </div>

          <div className="slider">
            <div className="icon">
              <i className="la la-lightbulb-o"></i>
            </div>
            <div className="sldier_content">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content
              </p>
            </div>
            <div className="sldier_button">
              <Button size="small">Get Reviews</Button>
            </div>
          </div>
        </Carousel>
        <div className="slider_trigger">
          <a
            href="#/"
            onClick={() => setSelectedIndex(selectedIndex - 1)}
            className="prev"
          >
            <i className="la la-angle-left"></i>
          </a>
          <a
            href="#/"
            onClick={() => setSelectedIndex(selectedIndex + 1)}
            className="next"
          >
            <i className="la la-angle-right"></i>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

function PaymentType() {
  const [isCreditcardChecked, setIsCreditcardChecked] = React.useState(true);
  const [isCashChecked, setIsCashChecked] = React.useState(true);
  const [isChecked, setIsChecked] = React.useState(true);
  const [isVenmoChecked, setIsVenmoChecked] = React.useState(true);
  const [isPaypalChecked, setIsPaypalChecked] = React.useState(true);
  const [isSquareChecked, setIsSquareChecked] = React.useState(true);

  return (
    <ul className="payment_type">
      <li>
        <Checkbox
          id="Credit card"
          isChecked={isCreditcardChecked}
          name="example-full"
          labelPadding="4px 0"
          onChange={setIsCreditcardChecked}
        >
          Credit Card
        </Checkbox>
      </li>
      <li>
        <Checkbox
          id="cash"
          isChecked={isCashChecked}
          name="example-full"
          labelPadding="4px 0"
          onChange={setIsCashChecked}
        >
          Cash
        </Checkbox>
      </li>
      <li>
        <Checkbox
          id="Check"
          isChecked={isChecked}
          name="example-full"
          labelPadding="4px 0"
          onChange={setIsChecked}
        >
          Check
        </Checkbox>
      </li>
      <li>
        <Checkbox
          id="Venmo"
          isChecked={isVenmoChecked}
          name="example-full"
          labelPadding="4px 0"
          onChange={setIsVenmoChecked}
        >
          Venmo
        </Checkbox>
      </li>
      <li>
        <Checkbox
          id="Paypal"
          isChecked={isPaypalChecked}
          name="example-full"
          labelPadding="4px 0"
          onChange={setIsPaypalChecked}
        >
          Paypal
        </Checkbox>
      </li>
      <li>
        <Checkbox
          id="Square"
          isChecked={isSquareChecked}
          name="example-full"
          labelPadding="4px 0"
          onChange={setIsSquareChecked}
        >
          Square
        </Checkbox>
      </li>
    </ul>
  );
}

export const Settings = () => (
  <React.Fragment>
    <section className="section-default">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            {/* First Section*/}
            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <h1>Profile Strength good start</h1>
                <div className="staging">
                  <ul>
                    <li className="active">
                      <span className="icons">
                        <i className="la la-check"></i>
                      </span>
                    </li>
                    <li>
                      <span className="icons">
                        <i className="la la-check"></i>
                      </span>
                    </li>
                    <li>
                      <span className="icons">
                        <i className="la la-check"></i>
                      </span>
                      <span className="icons float-right">
                        <i className="la la-check"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="edit_profile_section">
                <CarouselDemo />
              </div>
            </div>

            {/* Second Section*/}

            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <div className="profile_holder">
                  <div className="image_holder">
                    <img src={require("assets/images/map.jpg")} alt="" />
                  </div>
                  <div className="profie_info">
                    <h4>Meditations</h4>
                    <StarRating rating={0} size="medium" />{" "}
                    <a href="#/" className="tp-button tp-button--clear">
                      Ask for review
                    </a>
                  </div>
                </div>

                <div className="detais_info">
                  <ul>
                    <li>
                      <div className="de_info">
                        <div className="leftinfo">
                          <p>
                            <i className="la la-phone"></i>{" "}
                            <strong>Phone</strong>
                          </p>
                          <p>(713) 834-2650</p>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="de_info">
                        <div className="leftinfo">
                          <p>
                            <i className="la la-external-link"></i>{" "}
                            <strong>Website</strong>
                          </p>
                          <p>http://www.websitename.com</p>
                        </div>
                        <div className="right_btn">
                          <button
                            type="button"
                            className="tp-button tp-button--tertiary tp-button--small"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="de_info">
                        <div className="leftinfo">
                          <p>
                            <i className="la la-map-marker"></i>{" "}
                            <strong>Address</strong>
                          </p>
                          <p>Leamder, TX 78641</p>
                        </div>
                        <div className="right_btn"></div>
                      </div>
                    </li>

                    <li>
                      <div className="de_info">
                        <div className="leftinfo">
                          <p>
                            <i className="la la-trophy"></i>{" "}
                            <strong>Year founded</strong>
                          </p>
                          <p>2019</p>
                        </div>
                        <div className="right_btn"></div>
                      </div>
                    </li>

                    <li>
                      <div className="de_info">
                        <div className="leftinfo">
                          <p>
                            <i className="la la-users"></i>{" "}
                            <strong>Number of employees</strong>
                          </p>
                          <p>1</p>
                        </div>
                        <div className="right_btn"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 3rd  Section*/}

            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <label className="tp-label">Service details</label>
                <select className="tp-select tp-select--full tp-select--small">
                  <option>Reili Lessons</option>
                </select>

                <p className="text-center mt-3">
                  No service details available.
                  <br />
                  Select another service.
                </p>
              </div>
            </div>

            {/* 4th  Section*/}

            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <label className="tp-label">Photos and videos</label>
                <div className="image_upload_gal">
                  <Row>
                    <Col>
                      <div className="image_uploadplaceholder">
                        <label className="upload_button">
                          <img src={require("assets/images/plu.jpg")} alt="" />
                          <input type="file" hidden />
                        </label>
                      </div>
                    </Col>
                    <Col>
                      <div className="image_placeholder"></div>
                    </Col>
                    <Col>
                      <div className="image_placeholder"></div>
                    </Col>
                    <Col>
                      <div className="image_placeholder"></div>
                    </Col>
                    <Col>
                      <div className="image_placeholder"></div>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="edit_profile_section">
                <Card className="new_card">
                  <div className="new-content">
                    <p>
                      <strong>Show off your business</strong>
                    </p>
                    <p>
                      Include photos of your work (before and after), team,
                      workspace, or equipment.
                    </p>
                  </div>
                  <div className="new-button">
                    <button className="tp-button tp-button--small">
                      Add Photos
                    </button>
                  </div>
                </Card>
              </div>
            </div>

            {/* 5th  Section*/}
            <div className="lil_section">
              <p className="mb-2">
                <span>
                  <i className="la la-bar-chart"></i>
                </span>
                Other props in your market havean average of{" "}
                <strong>5 photos and videos</strong>
              </p>
            </div>

            {/* 6th  Section*/}
            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <p className="mb-2">
                  <strong>Social media</strong>
                </p>
                <div className="profile_socil">
                  <a
                    className="tp-button tp-button--tertiary tp-button--small"
                    href="#/"
                  >
                    <i className="la la-facebook-square"></i>
                    Add Facebook
                  </a>

                  <a
                    className="tp-button tp-button--tertiary tp-button--small"
                    href="#/"
                  >
                    <i className="la la-instagram"></i>
                    Add Instagram
                  </a>

                  <a
                    className="tp-button tp-button--tertiary tp-button--small"
                    href="#/"
                  >
                    <i className="la la-twitter"></i>
                    Add Twitter
                  </a>
                </div>
              </div>
            </div>

            {/* 6th  Section*/}

            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <div className="p_heading">
                  <div className="title">
                    <p>
                      <strong>Payment methods accepted</strong>
                    </p>
                  </div>
                  <div className="btns">
                    <a href="#/" className="save_btn">
                      Save
                    </a>
                    <a href="#/" className="can_btn">
                      Cancel
                    </a>
                  </div>
                </div>

                <div className="">
                  <PaymentType />
                </div>
              </div>
            </div>

            {/* 7th  Section*/}
            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <p className="mb-2">
                  <strong>Your introduction</strong>
                </p>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letter
                </p>
              </div>
            </div>

            {/* 8th  Section*/}
            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <p className="mb-2">
                  <strong>Reviews</strong>
                </p>
                <div className="reviews_container">
                  <div className="reviews_overall">
                    <p className="mb-2">
                      <strong>0.0</strong>
                    </p>
                    <StarRating rating={0} size="medium" />
                    <p className="mt-2">0 reviews</p>
                  </div>

                  <div className="r_cate">
                    <div className="detail_review">
                      <div className="number">
                        5 <i className="la la-star"></i>
                      </div>
                      <div className="progress_bar">
                        <ProgressBar now={0} />
                      </div>
                      <div className="persentage">0%</div>
                    </div>

                    <div className="detail_review">
                      <div className="number">
                        4 <i className="la la-star"></i>
                      </div>
                      <div className="progress_bar">
                        <ProgressBar now={0} />
                      </div>
                      <div className="persentage">0%</div>
                    </div>

                    <div className="detail_review">
                      <div className="number">
                        3 <i className="la la-star"></i>
                      </div>
                      <div className="progress_bar">
                        <ProgressBar now={0} />
                      </div>
                      <div className="persentage">0%</div>
                    </div>

                    <div className="detail_review">
                      <div className="number">
                        2 <i className="la la-star"></i>
                      </div>
                      <div className="progress_bar">
                        <ProgressBar now={0} />
                      </div>
                      <div className="persentage">0%</div>
                    </div>

                    <div className="detail_review">
                      <div className="number">
                        1 <i className="la la-star"></i>
                      </div>
                      <div className="progress_bar">
                        <ProgressBar now={0} />
                      </div>
                      <div className="persentage">0%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 9th  Section*/}
            <div className="edit_profile_warpper">
              <div className="edit_profile_section">
                <p className="mb-2">
                  <strong>Business hours</strong>
                </p>
                <p>Central Time Zone</p>
                <ul className="times">
                  <li>
                    <div className="tp-grid">
                      <div className="tp-col tp-col--4">Sunday</div>
                      <div className="tp-col tp-col--8">
                        8:00 a.m to 7:00 p.m.
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="tp-grid">
                      <div className="tp-col tp-col--4">Monday</div>
                      <div className="tp-col tp-col--8">
                        8:00 a.m to 7:00 p.m.
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="tp-grid">
                      <div className="tp-col tp-col--4">Tuesday</div>
                      <div className="tp-col tp-col--8">
                        8:00 a.m to 7:00 p.m.
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="tp-grid">
                      <div className="tp-col tp-col--4">Wednesday</div>
                      <div className="tp-col tp-col--8">
                        8:00 a.m to 7:00 p.m.
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="tp-grid">
                      <div className="tp-col tp-col--4">Thursday</div>
                      <div className="tp-col tp-col--8">
                        8:00 a.m to 7:00 p.m.
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="tp-grid">
                      <div className="tp-col tp-col--4">Friday</div>
                      <div className="tp-col tp-col--8">
                        8:00 a.m to 7:00 p.m.
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="tp-grid">
                      <div className="tp-col tp-col--4">Saturday</div>
                      <div className="tp-col tp-col--8">
                        8:00 a.m to 7:00 p.m.
                      </div>
                    </div>
                  </li>
                </ul>

                <div className="note">
                  <p>
                    <i className="la la-calendar-plus-o"></i>
                    Not available on a specificdate or time?{" "}
                    <span>Block your calendar.</span>
                  </p>
                </div>

                <p>
                  <strong>Notice needed before doing a job:</strong> 1day(s)
                </p>
                <p>
                  <strong>Customers can book you in advance up to:</strong> 24
                  months(s)
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>
);
