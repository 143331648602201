import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  isPermitted,
  redirectTo = "/login",
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isPermitted === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={redirectTo} />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isPermitted: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string
};

export default PrivateRoute;
