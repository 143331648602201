import React from "react";
import { Checkbox } from "@thumbtack/thumbprint-react";
export class CountryCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: undefined
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(isChecked) {
    this.setState({ isChecked });
  }

  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <div className="divider_box">
          <Checkbox isChecked={this.state.isChecked} onChange={this.onChange}>
            <div className="countyName">
              <h4>{props.title}</h4>
              <p>{props.content}</p>
            </div>
          </Checkbox>
        </div>
      </React.Fragment>
    );
  }
}
