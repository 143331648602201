import React from "react";
import "./Photos.css";
import { Row, Col, Container } from "react-bootstrap";

export const Photos = () => (
  <React.Fragment>
    <Container>
      <section className="vh-100">
        <Row>
          <Col md="3"></Col>
          <Col md="9">
            <Row>
              <Col md="6">
                <div className="photobox">
                  <h1 className="photo-title">Add Your profile photo</h1>
                  <p className="photo-content">
                    Make a good first impression. Upload a clear photo of
                    yourself.
                  </p>
                </div>
              </Col>
              <Col md="6"></Col>
              <Col md="6">
                <div className="avatar_container">
                  <div className="image_holder"></div>
                  <div className="upload_sytem">
                    <label className="upload_btn tp-button tp-button--small">
                      Upload
                      <input type="file" hidden />
                    </label>
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="phototips">
                  <h4>Photo Tips</h4>
                  <ul>
                    <li>A photo of your face works better than a logo.</li>
                    <li>Make sure to smile.</li>
                    <li>Try to use a solid background.</li>
                  </ul>
                  <img src={require("assets/images/images.jpg")} alt="" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </Container>
  </React.Fragment>
);
