import { API } from "helpers";
import { authHeader } from "helpers";
import { store } from "helpers";

export const userService = {
  getStarted,
  confirmation,
  forgotPassword,
  changePassword,
  login,
  logout,
  updateProfile,
  createProfile,
  getUserEntity
};

function getStarted(data) {
  const params = {
    url: "/sessions/register",
    method: "post",
    data
  };

  return API.request(params);
}

function logout() {
  const params = {
    url: "/sessions/logout",
    method: "post",
    headers: authHeader()
  };

  return API.request(params);
}

function login(data) {
  const params = {
    url: "/sessions",
    method: "post",
    data
  };

  return API.request(params);
}

function confirmation(data) {
  const params = {
    url: "/sessions/confirm",
    method: "post",
    data
  };

  return API.request(params);
}

function forgotPassword(email) {
  const params = {
    url: "/sessions/forgot",
    method: "post",
    data: email
  };

  return API.request(params);
}

function changePassword(data) {
  const params = {
    url: "/sessions/confirm-forgot",
    method: "post",
    data
  };

  return API.request(params);
}

function createProfile(data) {
  const params = {
    url: "/entities",
    method: "post",
    headers: authHeader(),
    data
  };

  return API.request(params);
}

function updateProfile(data) {
  const params = {
    url: "/entities",
    method: "post",
    headers: authHeader(),
    data
  };

  return API.request(params);
}

function getUserEntity() {
  const {
    authentication: {
      user: { sub }
    }
  } = store.getState();

  const params = {
    url: `/entities/${sub}`,
    method: "get",
    headers: authHeader()
  };

  return API.request(params);
}
