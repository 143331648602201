import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import { leadsActions, alertActions } from "actions";
import { leadsService } from "services";
import { leadsConstants } from "../constants";

function* getLeads() {
  try {
    const { data } = yield call(leadsService.getLeads);

    yield put(leadsActions.getLeadsSuccess(data));
  } catch (err) {
    yield put(leadsActions.getLeadsError(err));
    yield put(alertActions.error(err.response.data.message));
  }
}

function* watchGetLeadsRequest() {
  yield takeLatest(leadsConstants.GET_LEADS_REQUEST, getLeads);
}

export default function* leadsSaga() {
  yield all([fork(watchGetLeadsRequest)]);
}
