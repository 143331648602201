import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import useForm from "react-hook-form";
import { FormNote, Label } from "@thumbtack/thumbprint-react";
import { userService } from "services";
import { useHistory } from "react-router-dom";
import { TextInput } from "components";
import { useAlert } from "helpers";

import qs from "qs";

import "./ConfirmCode.css";

export const ConfirmCode = () => {
  const { register, errors, handleSubmit } = useForm();
  const [setLoading] = useState(false);
  const { push } = useHistory();
  const [successAlert, errorAlert] = useAlert();

  const [params] = useState(
    qs.parse(window.location.search, { ignoreQueryPrefix: true })
  );

  if (!params.hasOwnProperty("email") || !params.hasOwnProperty("code")) {
    push("/login");
    errorAlert("Invalid link");
  }

  const onSubmit = async formData => {
    try {
      setLoading(true);
      const { data } = await userService.confirmation(formData);
      if (data) {
        successAlert("Your account has been activated successfully!");

        push("/login");
      }
    } catch (err) {
      errorAlert(
        err === 500 ? "Oops. Something went wrong!" : err.response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="section-default">
        <Container>
          <Row>
            <Col md="12">
              <h1 className="fancyheading text-center">Account Verification</h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="box-holder">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <ol className="tp-form-fields">
                    <li className="tp-form-field__item">
                      <Label hasError={errors.hasOwnProperty("code")}>
                        Confirmation Code
                      </Label>
                      <input
                        type="hidden"
                        name="email"
                        defaultValue={params.email}
                        ref={register({
                          required: "This field is required."
                        })}
                        data-testid="email"
                      />
                      <TextInput
                        type="text"
                        defaultValue={params.code}
                        ref={register({
                          required: "This field is required."
                        })}
                        name="code"
                        data-testid="code"
                        hasError={errors.code}
                      />
                      {errors.code && (
                        <div className="mb1">
                          <FormNote hasError>{errors.code.message}</FormNote>
                        </div>
                      )}
                    </li>
                    <li>
                      <button className="tp-button tp-button--full" type="submit">
                        Confirm Account
                      </button>
                    </li>
                    <li className="tp-form-field__item">
                      <p className="mt3">
                        We've sent you the confirmation code to your email
                      </p>

                      <p className="text-left small-text">
                        <br />
                        <a href="#/" className="link-default">
                          Resend Confirmation Code
                        </a>
                      </p>
                    </li>
                  </ol>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
