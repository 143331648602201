import { alertConstants } from "../constants";
import produce from "immer";

export const alert = produce((draft = {}, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      draft.type = "alert-success";
      draft.message = action.message;
      break;
    case alertConstants.ERROR:
      draft.type = "alert-danger";
      draft.message = action.message;
      break;
    case alertConstants.WARNING:
      draft.type = "alert-warning";
      draft.message = action.message;
      break;
    case alertConstants.CLEAR:
      return {};
    default:
      return draft;
  }
});
