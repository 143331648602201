import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Label, FormNote } from "@thumbtack/thumbprint-react";
import { regex } from "helpers";
import useForm from "react-hook-form";
import { useDispatch } from "react-redux";
import { userActions } from "actions";
import { Link } from "react-router-dom";
import { useToggle } from "helpers";
import { GetStartedModal } from "components";

export const Login = () => {
  const [toggled, toggle] = useToggle();
  const { register, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const onSubmit = async formData => {
    dispatch(userActions.loginRequest(formData));
  };

  return (
    <div className="section-default min-vh-100 d-flex align-items-center">
      <Container>
        <Row>
          <Col md="12">
            <h1 className="fancyheading text-center">Welcome Back</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="box-holder">
              <form onSubmit={handleSubmit(onSubmit)}>
                <ol className="tp-form-fields">
                  <li className="tp-form-field__item">
                    <Label
                      className="tp-label"
                      hasError={errors.hasOwnProperty("email")}
                      for="email"
                    >
                      Email Address
                    </Label>
                    <input
                      type="email"
                      id="email"
                      className={`tp-text-input tp-text-input--small ${errors.email &&
                        "tp-text-input--bad-news"}`}
                      ref={register({
                        required: "This field is required.",
                        pattern: {
                          value: regex.email,
                          message: "Please enter an email address."
                        }
                      })}
                      name="email"
                    />
                    {errors.email && (
                      <div className="mb1">
                        <FormNote hasError>{errors.email.message}</FormNote>
                      </div>
                    )}
                  </li>

                  <li className="tp-form-field__item">
                    <Label
                      for="password"
                      hasError={errors.hasOwnProperty("password")}
                    >
                      Password
                    </Label>
                    <input
                      id="password"
                      type="password"
                      className={`tp-text-input tp-text-input--small ${errors.password &&
                        "tp-text-input--bad-news"}`}
                      ref={register({
                        required: "This field is required."
                      })}
                      name="password"
                    />
                    {errors.password && (
                      <div className="mb1">
                        <FormNote hasError>{errors.password.message}</FormNote>
                      </div>
                    )}
                  </li>

                  <li className="tp-form-field__item">
                    <div className="tp-grid">
                      <div className="tp-col tp-col--6">
                        <div className="tp-input-wrap">
                          <input
                            className="tp-checkbox-input"
                            type="checkbox"
                            id="terms"
                            name="remember"
                            ref={register}
                          />
                          <div className="tp-checkbox-image"></div>
                          <label className="tp-label" htmlFor="terms">
                            {" "}
                            Remember Me
                          </label>
                        </div>
                      </div>

                      <div className="tp-col tp-col--6">
                        <p className="text-right">
                          <Link to="/forgot-password" className="link-default">
                            Forget Password?
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <button className="tp-button tp-button--full" type="submit">
                      Log In
                    </button>
                  </li>
                  <div className="text-divider">OR</div>

                  <p className="text-center small-text">
                    It is a long established fact that a reader will be The
                    point of using{" "}
                    <a href="#/" className="link-default">
                      Lorem Ipsum is that
                    </a>{" "}
                    it has a more-or-less normal distribution of letters
                  </p>
                </ol>
              </form>
            </div>

            <div className="text-center">
              <p>
                Don't Have an account{" "}
                <button className="link-default" onClick={toggle}>
                  Sign Up?
                </button>
                <GetStartedModal toggle={toggle} toggled={toggled} />
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
