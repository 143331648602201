import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import useForm from "react-hook-form";
import { FormNote, Label } from "@thumbtack/thumbprint-react";
import { regex } from "helpers";
import { userService } from "services";
import { useHistory } from "react-router-dom";
import { useAlert } from "helpers";

export const ForgotPassword = () => {
  const { register, errors, handleSubmit } = useForm();
  const [setLoading] = useState(false);
  const { push } = useHistory();
  const [successAlert, errorAlert] = useAlert();

  const onSubmit = async formData => {
    try {
      setLoading(true);
      const { data } = await userService.forgotPassword(formData);
      if (data) {
        successAlert("Check your email for password confirmation");

        push("/login");
      }
    } catch (err) {
      errorAlert(
        err.status === 500
          ? "Oops. Something went wrong!"
          : err.response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="section-default">
      <Container>
        <Row>
          <Col md="12">
            <h1 className="fancyheading text-center">Reset your password</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="box-holder">
              <form onSubmit={handleSubmit(onSubmit)}>
                <ol className="tp-form-fields">
                  <li className="tp-form-field__item">
                    <Label hasError={errors.hasOwnProperty("email")}>
                      Email Address
                    </Label>
                    <input
                      type="email"
                      className={`tp-text-input tp-text-input--small ${errors.email &&
                        "tp-text-input--bad-news"}`}
                      ref={register({
                        required: "This field is required.",
                        pattern: {
                          value: regex.email,
                          message: "Please enter an email address."
                        }
                      })}
                      name="email"
                    />
                    {errors.email && (
                      <div className="mb1">
                        <FormNote hasError>{errors.email.message}</FormNote>
                      </div>
                    )}
                  </li>

                  <li className="tp-form-field__item">
                    <p className="mt3">
                      Enter the email address associated with your account, and
                      we’ll email you a link to reset your password.
                    </p>
                  </li>
                  <li>
                    <button class="tp-button tp-button--full" type="submit" width="full">
                      Send Reset Link
                    </button>
                  </li>
                </ol>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
