import { leadsConstants } from "../constants";

export const leadsActions = {
  getLeadsRequest,
  getLeadsSuccess,
  getLeadsError
};

function getLeadsRequest() {
  return { type: leadsConstants.GET_LEADS_REQUEST };
}

function getLeadsSuccess(leads) {
  return { type: leadsConstants.GET_LEADS_SUCCESS, leads };
}

function getLeadsError(error) {
  return { type: leadsConstants.GET_LEADS_FAILURE, error };
}
